import { Box } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

/**
 * A vertical stack of elements.
 * @param {import("react").ReactElement} component The component to use for the stack.
 * @param {string} [justify] The CSS `justify-content` property.
 * @param {string} [align] The CSS `align-items` property.
 * @param {string} [spacing] The CSS `gap` property.
 * @param {import("react").ReactElement} children The children to render inside the flex.
 * @param {import("@material-ui/core").BoxProps} props The props to pass to the flex container.
 * @returns {import("react").ReactElement} The vertical flex component.
 */
export default function VStack({
  component = "div",
  justify = "start",
  align = "stretch",
  spacing = "1rem",
  children,
  style = {},
  ...props
}) {
  return (
    <Box
      component={component}
      display="flex"
      flexDirection="column"
      justifyContent={justify}
      alignItems={align}
      style={{
        flexDirection: "column",
        justifyContent: justify,
        alignItems: align,
        gap: spacing,
        ...style,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export const justifyOptions = [
  "flex-start",
  "start",
  "flex-end",
  "end",
  "center",
  "space-between",
  "space-around",
  "space-evenly",
]

export const alignOptions = [
  "flex-start",
  "start",
  "flex-end",
  "end",
  "center",
  "stretch",
]

VStack.propTypes = {
  component: PropTypes.elementType,
  justify: PropTypes.oneOf(justifyOptions),
  align: PropTypes.oneOf(alignOptions),
  spacing: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}
