import { IconButton, makeStyles } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/CancelTwoTone"
import PropTypes from "prop-types"
import { BORDER_TYPES_ENUM } from "../../constants"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import { StyledInput } from "../Profile/pages/ProfilePage"
import ThemedTooltip from "./ThemedTooltip"
import { IconSearch } from "@tabler/icons-react"

export const variants = ["light", "dark"]

const useStyles = makeStyles((theme) => ({
  borderClass: (props) => ({
    border: `1px solid ${
      props?.isFocused
        ? "transparent"
        : props?.variant === BORDER_TYPES_ENUM.DARK.variant
        ? BORDER_TYPES_ENUM.DARK.value
        : BORDER_TYPES_ENUM.LIGHT.value
    }`,
  }),
}))

/**
 * A themed search bar.
 * @param {Object} [style] The CSS style object.
 * @param {'light'|'dark'} [variant] The variant of the search bar.
 * @param {string} [placeholder] The placeholder text.
 * @param {string} [value] The value of the search bar.
 * @param {Function} [onChange] The function to call when the value changes.
 * @param {Function} [onClear] The function to call when the clear button is clicked.
 * @param {import("@material-ui/core").InputProps} props The props to pass to the input.
 * @returns {import("react").ReactElement} The search bar.
 */
export default function SearchBar({
  style = {},
  variant = BORDER_TYPES_ENUM.LIGHT.variant,
  placeholder = "",
  value = "",
  onChange = () => {},
  onClear = () => {},
  ...props
}) {
  const classes = useStyles({ variant, isFocused: value.length > 0 })

  return (
    <StyledInput
      autoFocus
      startAdornment={
        <IconSearch
          stroke={2}
          color={apxTheme.palette.text.hint}
        />
      }
      placeholder={placeholder ?? ""}
      value={value ?? ""}
      onChange={onChange}
      {...props}
      style={{
        ...style,
        height: "44px",
        fontWeight: "600",
        fontSize: "14px",
        paddingInline: "10px",
        color: apxTheme.palette.text.primary,
        backgroundColor: "#FFFFFF",
      }}
      classeName={classes.borderClass}
      endAdornment={
        value.length > 0 ? (
          <ThemedTooltip
            title="Clear"
            dark
          >
            <IconButton
              size="small"
              onClick={onClear}
            >
              <ClearIcon
                fontSize="small"
                color="secondary"
              />
            </IconButton>
          </ThemedTooltip>
        ) : null
      }
    />
  )
}

SearchBar.propTypes = {
  style: PropTypes.object,
  variant: PropTypes.oneOf(variants),
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
}
