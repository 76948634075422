import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import SurveysIcon from "@material-ui/icons/AssignmentOutlined"
import CampaignsIcon from "@material-ui/icons/ChatOutlined"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import TestDevicesIcon from "@material-ui/icons/ImportantDevicesOutlined"
import classNames from "classnames"
import { Fragment, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { isExperienceDashboard, isSuper } from "../../../../../config"
import { FEATURE_KEY_ENUM } from "../../../../../constants"
import { DASHBOARD_ENDPOINTS } from "../../../../../constants/Endpoints"
import { isAccessable, isUserRestricted } from "../../../../../utils"
import { useDeepCompareMemoNoCheck } from "../../../../../utils/use-deep-compare/use-deep-compare-memo"
import { useTrackedState } from "../../../store"
import { styles } from "../styling/CustomCSS"

const METRICS = "/assets/img/metrics.png"
const SEGMENT = "/assets/img/segment.png"
const USERS = "/assets/img/explorer.png"
const ANALYSIS = "/assets/img/analysis.png"
const INSIGHTS = "/assets/img/insights.png"
const SETTINGS = "/assets/img/settings.png"
const COHORT = "/assets/img/cohort.png"
const DOCS = "/assets/img/docs.png"
const PUSH_IMG = "/assets/img/push.png"
const METRICS_ACTIVE = "/assets/img/metrics_active.png"
const SEGMENT_ACTIVE = "/assets/img/segment_active.png"
const USERS_ACTIVE = "/assets/img/explorer_active.png"
const ANALYSIS_ACTIVE = "/assets/img/analysis_active.png"
const INSIGHTS_ACTIVE = "/assets/img/insights_active.png"
const SETTINGS_ACTIVE = "/assets/img/settings_active.png"
const COHORT_ACTIVE = "/assets/img/cohort_active.png"
const PUSH_ACTIVE_IMG = "/assets/img/push.png"

const {
  COHORTS,
  CUSTOM_REPORTS,
  DASHBOARD,
  USER_EXPLORER,
  SEGMENTS,
  EVENT_ANALYSIS,
  RETENTION,
  UNINSTALLS,
  FUNNELS,
  PATH,
  PUSH,
  PREPROCESS,
  WALKTHROUGHS,
  SURVEYS,
  SETTINGS_SDK,
  SETTINGS_TEST_DEVICES,
  SETTINGS_GENERAL,
  SETTINGS_IAM,
  SETTINGS_DATA,
  SETTINGS_EM,
} = DASHBOARD_ENDPOINTS

const ENDPOINT_MAPPER = {
  Dashboard: DASHBOARD,
  Segments: SEGMENTS,
  "User Explorer": USER_EXPLORER,
  Events: EVENT_ANALYSIS,
  Retention: RETENTION,
  Uninstalls: UNINSTALLS,
  Path: PATH,
  Funnels: FUNNELS,
  General: SETTINGS_GENERAL,
  SDK: SETTINGS_SDK,
  "Test Devices": SETTINGS_TEST_DEVICES,
  "Events Management": SETTINGS_EM,
  Downloads: SETTINGS_DATA,
  IAM: SETTINGS_IAM,
  PUSH: PUSH,
}

const ACCESS_ENDPOINT_MAPPER = {
  Dashboard: FEATURE_KEY_ENUM.DASHBOARD,
  Segments: FEATURE_KEY_ENUM.SEGMENTS,
  "User Explorer": FEATURE_KEY_ENUM.USER_EXPLORER,
  Events: FEATURE_KEY_ENUM.EVENT_ANALYSIS,
  Retention: FEATURE_KEY_ENUM.RETENTION,
  Uninstalls: FEATURE_KEY_ENUM.UNINSTALLS,
  Path: FEATURE_KEY_ENUM.PATH,
  Funnels: FEATURE_KEY_ENUM.FUNNELS,
  General: FEATURE_KEY_ENUM.SETTINGS_GENERAL,
  SDK: FEATURE_KEY_ENUM.SETTINGS_SDK,
  "Test Devices": FEATURE_KEY_ENUM.SETTINGS_TEST_DEVICES,
  Downloads: FEATURE_KEY_ENUM.SETTINGS_DATA,
  IAM: FEATURE_KEY_ENUM.SETTINGS_IAM,
  PUSH: FEATURE_KEY_ENUM.PUSH,
}

const ANALYSIS_OPTIONS = [
  "Events",
  "Retention",
  "Uninstalls",
  "Path",
  "Funnels",
]
const ADMIN_SETTINGS_OPTIONS = [
  "General",
  "SDK",
  "Test Devices",
  "Events Management",
  "Downloads",
  "IAM",
]
const SETTINGS_OPTIONS = ["Test Devices", "Downloads", "Events Management"]

const SideBar = (props) => {
  const {
    appState: {
      basic_info: { customers, customer_id },
    },
    classes,
    location: { pathname = "" } = {},
    session: {
      user: { email },
    },
  } = props
  const app = props.appState
  const parentState = useTrackedState()
  const { selectedTab } = parentState

  const isActiveLink = (endPoint) => {
    if (typeof endPoint !== "string") {
      if (endPoint === SETTINGS_OPTIONS && pathname.includes("settings"))
        return true
      else if (
        endPoint === ANALYSIS_OPTIONS &&
        (pathname.includes("event-analysis") ||
          pathname.includes("retention") ||
          pathname.includes("uninstalls") ||
          pathname.includes("path") ||
          pathname.includes("funnels"))
      )
        return true
      else return false
    } else {
      return pathname.includes(endPoint)
    }
  }

  const isAdmin = email === customer_id || isSuper(email)

  const [options, setOptions] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const analysisOptions = useDeepCompareMemoNoCheck(
    () =>
      ANALYSIS_OPTIONS.filter((eachOption) => {
        return isAccessable(app, ACCESS_ENDPOINT_MAPPER[eachOption])
      }),
    [app],
  )

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOptions([])
  }

  const { orgId } = useParams()

  const navigationLink = (appId, endPoint) => {
    return { pathname: `/orgs/${orgId}/apps/${appId}/${endPoint}` }
  }

  const sideBarContents = () => {
    if (selectedTab === 1) {
      return (
        <>
          {isAccessable(app, FEATURE_KEY_ENUM.CUSTOM_REPORTS) && (
            <Link to={navigationLink(app.app_id, CUSTOM_REPORTS)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(CUSTOM_REPORTS) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={
                        isActiveLink(CUSTOM_REPORTS) ? METRICS_ACTIVE : METRICS
                      }
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      Custom Reports
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.SEGMENTS) && (
            <Link to={navigationLink(app.app_id, SEGMENTS)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(SEGMENTS) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={isActiveLink(SEGMENTS) ? SEGMENT_ACTIVE : SEGMENT}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      Segments
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.USER_EXPLORER) && (
            <Link to={navigationLink(app.app_id, USER_EXPLORER)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(USER_EXPLORER) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={isActiveLink(USER_EXPLORER) ? USERS_ACTIVE : USERS}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      User Explorer
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
          {analysisOptions.length > 0 && (
            <Button
              className={classNames(
                classes.container,
                isActiveLink(ANALYSIS_OPTIONS) && classes.active,
              )}
              aria-controls="long-menu1"
              aria-haspopup="true"
              onClick={(e) => {
                setOptions(analysisOptions)
                handleClick(e)
              }}
              style={{
                height: "100px",
                width: "100px",
                marginTop: "15px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                <div
                  style={{
                    color: "#8093A2",
                    height: "30px",
                  }}
                >
                  <img
                    alt={""}
                    src={
                      isActiveLink(ANALYSIS_OPTIONS)
                        ? ANALYSIS_ACTIVE
                        : ANALYSIS
                    }
                  />
                </div>
                <div>
                  <span
                    style={{
                      color: "inherit",
                      fontSize: "10px",
                    }}
                  >
                    Analysis
                  </span>
                </div>
              </div>
            </Button>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.PREPROCESS) && (
            <Link to={navigationLink(app.app_id, PREPROCESS)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(PREPROCESS) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={
                        isActiveLink(PREPROCESS) ? INSIGHTS_ACTIVE : INSIGHTS
                      }
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      Behaviour
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
        </>
      )
    } else {
      return (
        <>
          {isAccessable(app, FEATURE_KEY_ENUM.DASHBOARD) && (
            <Link to={navigationLink(app.app_id, DASHBOARD)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(DASHBOARD) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <DashboardIcon
                      fontSize="large"
                      style={{
                        color: isActiveLink(DASHBOARD) ? "#fff" : "#7A8D9C",
                      }}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: isActiveLink(DASHBOARD) ? "#fff" : "#7A8D9C",
                        fontSize: "12px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      Overview
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.COHORTS) && (
            <Link to={navigationLink(app.app_id, COHORTS)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(COHORTS) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={isActiveLink(COHORTS) ? COHORT_ACTIVE : COHORT}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      Cohorts
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.WALKTHROUGHS) && (
            <Link to={navigationLink(app.app_id, WALKTHROUGHS)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(WALKTHROUGHS) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <CampaignsIcon
                      fontSize="large"
                      style={{
                        color: isActiveLink(WALKTHROUGHS) ? "#fff" : "#7A8D9C",
                      }}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: isActiveLink(WALKTHROUGHS) ? "#fff" : "#7A8D9C",
                        fontSize: "12px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      Campaigns
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.SURVEYS) && (
            <Link to={navigationLink(app.app_id, SURVEYS)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(SURVEYS) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <SurveysIcon
                      fontSize="large"
                      style={{
                        color: isActiveLink(SURVEYS) ? "#fff" : "#7A8D9C",
                      }}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: isActiveLink(SURVEYS) ? "#fff" : "#7A8D9C",
                        fontSize: "12px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      Surveys
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}

          {!isExperienceDashboard() && (
            <a
              target="_blank"
              href="https://docs.apxor.com"
              rel="noopener noreferrer"
            >
              <Button
                className={classNames(classes.container)}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={DOCS}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      Docs
                    </span>
                  </div>
                </div>
              </Button>
            </a>
          )}
          {isAccessable(app, FEATURE_KEY_ENUM.PUSH) && (
            <Link to={navigationLink(app.app_id, PUSH)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(PUSH) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <img
                      alt={""}
                      src={isActiveLink(PUSH) ? PUSH_ACTIVE_IMG : PUSH_IMG}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "inherit",
                        fontSize: "10px",
                      }}
                    >
                      Push
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          )}
        </>
      )
    }
  }

  return (
    <div>
      {!isUserRestricted(customers, email) && (
        <Fragment>
          <Menu
            id={"long-menu"}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 10 * 46,
                width: "160px",
                marginLeft: "90px",
                background: "white",
                color: "#002845",
                fontFamily: "Manrope",
                lineHeight: "22px",
              },
            }}
          >
            {options.map((option) => (
              <Link to={navigationLink(app.app_id, ENDPOINT_MAPPER[option])}>
                <MenuItem
                  className={classNames(classes.menuItem)}
                  dense
                  id="long-menu"
                  key={option}
                  onClick={() => {
                    handleClose()
                  }}
                  style={{ height: "46px" }}
                >
                  <span style={{ color: "#002845" }}>{option}</span>
                </MenuItem>
              </Link>
            ))}
          </Menu>
          {sideBarContents()}
          {isExperienceDashboard() ? (
            <Link to={navigationLink(app.app_id, SETTINGS_TEST_DEVICES)}>
              <Button
                className={classNames(
                  classes.container,
                  isActiveLink(SETTINGS_TEST_DEVICES) && classes.active,
                )}
                aria-controls="long-menu1"
                aria-haspopup="true"
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      color: "#8093A2",
                      height: "30px",
                    }}
                  >
                    <TestDevicesIcon
                      fontSize="large"
                      style={{
                        color: isActiveLink(SETTINGS_TEST_DEVICES)
                          ? "#fff"
                          : "#7A8D9C",
                      }}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: isActiveLink(SETTINGS_TEST_DEVICES)
                          ? "#fff"
                          : "#7A8D9C",
                        fontSize: "12px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      Test Devices
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
          ) : (
            <Button
              className={classNames(
                classes.container,
                isActiveLink(SETTINGS_OPTIONS) && classes.active,
              )}
              aria-controls="long-menu1"
              aria-haspopup="true"
              onClick={(e) => {
                setOptions(isAdmin ? ADMIN_SETTINGS_OPTIONS : SETTINGS_OPTIONS)
                handleClick(e)
              }}
              style={{
                height: "100px",
                width: "100px",
                marginTop: "15px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                <div
                  style={{
                    color: "#8093A2",
                    height: "30px",
                  }}
                >
                  <img
                    alt={""}
                    src={
                      isActiveLink(SETTINGS_OPTIONS)
                        ? SETTINGS_ACTIVE
                        : SETTINGS
                    }
                  />
                </div>
                <div>
                  <span
                    style={{
                      color: "inherit",
                      fontSize: "10px",
                    }}
                  >
                    Settings
                  </span>
                </div>
              </div>
            </Button>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(SideBar)
