export const API_BASE =
  process.env.NODE_ENV !== "production"
    ? "https://localhost/host-redirect/"
    : window.API_BASE

export const API_ROOT = window.API_ROOT

export const DEMO_APP_ID = "ac254c4b-6840-448b-a2e7-1892fe1443bc"
export const APP_ID_FOR_DEMO = "ca2ca32b-0c9e-4d6b-93be-99c35ca4bdbc"

export const DEMO_START_DATE = "2018-12-01"
export const DEMO_END_DATE = "2018-12-31"

// Prod WebSDK Site IDs
export const ASEAN_NUDGES_SITE_ID = "3eba3e9b-f9fe-49be-99eb-07300f94f2eb"
export const EXPERIENCE_SITE_ID = "222d9eca-5cab-48a9-8c27-59c1b868ad6e"

// QA WebSDK Site IDs
export const QA_SITE_ID = "8c2c3a9e-f197-48b5-a71d-6282e6f55243"
export const QA_EXPERIENCE_SITE_ID = "6ba7e717-a05a-4033-be9d-82efbd6d3281"

/**
 * Returns the site ID for the current environment.
 * @returns {string} `siteId` The Site ID of the current dashboard for the Apxor Web SDK to use.
 */
export function getSiteId() {
  if (isExperienceDashboard()) {
    return ["qa.experience.apxor.com", "localhost"].every(
      (domain) => domain !== window.location.hostname,
    )
      ? EXPERIENCE_SITE_ID
      : QA_EXPERIENCE_SITE_ID
  } else {
    return ["qa.apxor.com", "dev.internal.apxor.com", "localhost"].every(
      (domain) => domain !== window.location.hostname,
    )
      ? ASEAN_NUDGES_SITE_ID
      : QA_SITE_ID
  }
}

export function wrapperNudges(callback) {
  if (["nudges.apxor.com"].includes(window.location.hostname)) {
    callback()
  }
}
export const EXPERIENCE_DASHBOARDS = [
  "experience.apxor.com",
  "qa.experience.apxor.com",
  // NOTE: Toggle the below line to enable Apxor Experience on localhost
  // ...(process.env.NODE_ENV === "development" ? ["localhost"] : []),
]

export const APXOR_ORG_ID = "00a932d0-7a6b-4d0d-8699-b4cafa6b7106"
export const DEMO_ECOMMERCE_APP_ID = "ed0d5f3f-9fd3-4dd5-aa01-c62a823e89c9"

// Checks if the logged in user is super user, you know what I mean ;^)
export const isSuper = (email) =>
  email === decode("APX", "IDQ1KD4YICAgLiJ2Ij81")

// FIXME: Make sure we remove unused keys in this
export const ApxorAppInfo = {
  logo: "/assets/img/ApxorLogo.svg",
  name: "Apxor",
  birthDaySrc: "/assets/img/birthdayLogo.png",
  whiteLogo: "/assets/img/logo-white.png",
  users: "/assets/img/users.png",
  favicon: "/assets/img/favicon.png",
  onBoarding: "/assets/img/onBoarding.png",
}

export const IOS_DEVICE_MAP = {
  i386: "iPhone Simulator",
  x86_64: "iPhone Simulator",
  "iPad1,1": "iPad",
  "iPad1,2": "iPad 3G",
  "iPad2,1": "2nd Gen iPad",
  "iPad2,2": "2nd Gen iPad GSM",
  "iPad2,3": "2nd Gen iPad CDMA",
  "iPad2,4": "2nd Gen iPad New Revision",
  "iPad2,5": "iPad mini",
  "iPad2,6": "iPad mini GSM+LTE",
  "iPad2,7": "iPad mini CDMA+LTE",
  "iPad3,1": "3rd Gen iPad",
  "iPad3,2": "3rd Gen iPad CDMA",
  "iPad3,3": "3rd Gen iPad GSM",
  "iPad3,4": "4th Gen iPad",
  "iPad3,5": "4th Gen iPad GSM+LTE",
  "iPad3,6": "4th Gen iPad CDMA+LTE",
  "iPad4,1": "iPad Air (WiFi)",
  "iPad4,2": "iPad Air (GSM+CDMA)",
  "iPad4,4": "iPad mini Retina (WiFi)",
  "iPad4,5": "iPad mini Retina (GSM+CDMA)",
  "iPad4,6": "iPad mini Retina (China)",
  "iPad4,7": "iPad mini 3 (WiFi)",
  "iPad4,8": "iPad mini 3 (GSM+CDMA)",
  "iPad4,9": "iPad Mini 3 (China)",
  "iPad5,3": "iPad Air 2 (WiFi)",
  "iPad5,4": "iPad Air 2 (Cellular)",
  "iPad6,3": "iPad Pro (9.7 inch, Wi-Fi)",
  "iPad6,4": "iPad Pro (9.7 inch, Wi-Fi+LTE)",
  "iPad6,7": "iPad Pro (12.9 inch, Wi-Fi)",
  "iPad6,8": "iPad Pro (12.9 inch, Wi-Fi+LTE)",
  "iPhone1,1": "iPhone",
  "iPhone1,2": "iPhone 3G",
  "iPhone2,1": "iPhone 3GS",
  "iPhone3,1": "iPhone 4",
  "iPhone3,2": "iPhone 4 GSM Rev A",
  "iPhone3,3": "iPhone 4 CDMA",
  "iPhone4,1": "iPhone 4S",
  "iPhone5,1": "iPhone 5 GSM+LTE",
  "iPhone5,2": "iPhone 5 CDMA+LTE",
  "iPhone5,3": "iPhone 5C (GSM)",
  "iPhone5,4": "iPhone 5C (Global)",
  "iPhone6,1": "iPhone 5S (GSM)",
  "iPhone6,2": "iPhone 5S (Global)",
  "iPhone7,1": "iPhone 6 Plus",
  "iPhone7,2": "iPhone 6",
  "iPhone8,1": "iPhone 6s",
  "iPhone8,2": "iPhone 6s Plus",
  "iPhone8,3": "iPhone SE (GSM+CDMA)",
  "iPhone8,4": "iPhone SE (GSM)",
  "iPhone9,1": "iPhone 7",
  "iPhone9,2": "iPhone 7 Plus",
  "iPhone9,3": "iPhone 7",
  "iPhone9,4": "iPhone 7 Plus",
  "iPod1,1": "1st Gen iPod",
  "iPod2,1": "2nd Gen iPod",
  "iPod3,1": "3rd Gen iPod",
  "iPod4,1": "4th Gen iPod",
  "iPod5,1": "5th Gen iPod",
  "iPod7,1": "6th Gen iPod",
}

const b64_table =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="

const decode = (key, data) => {
  return xor_decode(key, b64_decode(data))
}

function b64_decode(data) {
  var o1,
    o2,
    o3,
    h1,
    h2,
    h3,
    h4,
    bits,
    i = 0,
    result = []
  if (!data) {
    return data
  }
  data += ""
  do {
    h1 = b64_table.indexOf(data.charAt(i++))
    h2 = b64_table.indexOf(data.charAt(i++))
    h3 = b64_table.indexOf(data.charAt(i++))
    h4 = b64_table.indexOf(data.charAt(i++))
    bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4
    o1 = (bits >> 16) & 0xff
    o2 = (bits >> 8) & 0xff
    o3 = bits & 0xff
    result.push(o1)
    if (h3 !== 64) {
      result.push(o2)
      if (h4 !== 64) {
        result.push(o3)
      }
    }
  } while (i < data.length)
  return result
}

function keyCharAt(key, i) {
  return key.charCodeAt(Math.floor(i % key.length))
}

function xor_decode(key, data) {
  return Array.from(data)
    .map(function (c, i) {
      return String.fromCharCode(c ^ keyCharAt(key, i))
    })
    .join("")
}

export function isExperienceDashboard() {
  return EXPERIENCE_DASHBOARDS.some(
    (dashboard) => window.location.hostname === dashboard,
  )
}

export function isApxorExperienceOrg(orgId) {
  return [
    "81f65e51-9625-46e6-82db-3d9c2f391813",
    "9bea4fc9-a1fe-41d4-8b32-2572354a74be",
  ].includes(orgId)
}
