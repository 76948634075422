import { callApi } from "../../../api"
import { withQueryStrings } from "../../../utils"
import { ROLES_ENUM } from "../../Dashboard/components/settings/constants"

const LOGIN_API = "authenticate/customer"
const LOGOUT_API = "logout"
const FORGOT_PASSWORD_API = "forgot-password"
const RESET_PASSWORD_API = "reset-password/customer"
const INTEGRATION_API = "integration"
const INVITEES_API = "invitees"
const INVITEE_API = "invitee"

/**
 * @typedef {Object} LoginResponse
 * @property {Object} user - JSON Object
 * @property {string} user.email - The email of the user
 * @property {string} user.name - The name of the user
 */

/**
 * Sends a login request to the server.
 * @param {string} email The `email` of the user.
 * @param {string} password The `password` of the user.
 * @returns {Promise<LoginResponse>} Returns a promise that resolves to {@link LoginResponse}.
 */
export function loginAPI(email, password) {
  const config = {
    method: "POST",
    body: JSON.stringify({
      userId: email,
      password,
    }),
  }

  const onSuccessCallback = (json) => {
    return {
      token: "1234-5678-9101-1213",
      user: json,
    }
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(LOGIN_API, config, onSuccessCallback, onFailureCallback)
}

/**
 * Sends a logout request to the server.
 * @returns {Promise<boolean>} Returns a promise that resolves to `true` if the logout was successful, `false` otherwise.
 */
export function logoutAPI() {
  const config = {
    method: "POST",
    body: "{}",
  }

  const onSuccessCallback = () => {
    return true
  }
  const onFailureCallback = () => {
    return false
  }

  return callApi(LOGOUT_API, config, onSuccessCallback, onFailureCallback)
}

/**
 * Sends a forgot password request to the server.
 * @param {string} email The `email` of the user.
 * @returns {Promise<boolean|string>} Returns a promise.
 */
export function forgotPasswordAPI(email) {
  const url = `${FORGOT_PASSWORD_API}/${email}?domain=${
    window.location.host !== "localhost"
      ? window.location.host
      : "dev.internal.apxor.com"
  }`
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (email) => {
    return email?.length > 0
  }
  const onFailureCallback = (error) => {
    return error?.data?.message
  }
  return callApi(url, config, onSuccessCallback, onFailureCallback)
}

/**
 * Sends a reset password request to the server.
 * @param {Object} auth The `auth` object.
 * @param {string} email The `email` of the user.
 * @param {string} password The NEW `password` of the user.
 * @param {string} token The `token` received via the magic link from email.
 * @returns {Promise} Returns a promise.
 */
export function resetPasswordAPI(auth, email, password, token) {
  const config = {
    method: "POST",
    auth,
    body: JSON.stringify({
      userId: email,
      token,
      password,
    }),
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    RESET_PASSWORD_API,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * @typedef {Object} IntegrationAuthStartDto
 * @property {string} name The `name` of the user.
 * @property {string} email_id The `email` of the user.
 * @property {string} job_title The `job_title` of the user.
 * @property {string} company_name The `company_name` of the user.
 * @property {string} phone_number The `phone_number` of the user.
 */

/**
 * Sends an email with a magic link to the user to set a password.
 * @param {IntegrationAuthStartDto} body The `body` object, refer {@link IntegrationAuthStartDto}.
 * @returns {Promise} Returns a promise.
 */
export function integrationAuthStartAPI(body) {
  const config = {
    method: "POST",
    body: JSON.stringify(body),
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INTEGRATION_API}/signup/start?domain=${
      window.location.host !== "localhost"
        ? window.location.host
        : "qa.apxor.com"
    }`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * @typedef {Object} IntegrationAuthFinishDto
 * @property {string} name The `name` of the user.
 * @property {string} password The `password` of the user.
 * @property {string} token The `token` received via the magic link from email.
 */

/**
 * Sets the given password to the associated new user account.
 * @param {IntegrationAuthFinishDto} body The `body` object, refer {@link IntegrationAuthFinishDto}.
 * @returns {Promise} Returns a promise.
 */
export function integrationAuthFinishAPI(body) {
  const config = {
    method: "POST",
    body: JSON.stringify(body),
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INTEGRATION_API}/signup/finish`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Checks if the given user is already registered.
 * @param {string} token The `token` received via the magic link from email.
 * @returns {Promise} Returns a promise.
 */
export function checkUserExistsAPI(token) {
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INTEGRATION_API}/user/exists?token=${token}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * @typedef {Object} InvitationData
 * @property {string} email_id - The email id of the user.
 * @property {RoleType[]} role - The role of the user.
 * @property {FlagType[]} flags - The allowed flags for the user.
 * @property {PermissionType[]} permissions - The allowed permissions for the user.

 * @typedef {Object} EditPermissionsDto
 * @property {string} customer_id - The email id of the user.
 * @property {RoleType[]} role - The role of the user.
 * @property {FlagType[]} flags - The allowed flags for the user.
 * @property {PermissionType[]} permissions - The allowed permissions for the user.
 */

/**
 * Sends an invitation to the user.
 * @param { string } appId The `appId` of the app.
 * @param {string} orgId The `orgId` of the organization.
 * @param {InvitationData} invitation_data The secondary details of the user to be invited.
 * @returns {Promise} Returns a promise.
 */
export function inviteUserAPI(appId, orgId, invitation_data) {
  const config = {
    method: "POST",
    body: JSON.stringify({
      email_id: invitation_data.email_id,
      role: invitation_data.role ?? ROLES_ENUM.SUPPORT.value,
      flags: invitation_data?.flags ?? [],
      permissions: invitation_data?.permissions ?? [],
    }),
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }

  const queryString = new URLSearchParams({
    appId,
    orgId,
    domain:
      window.location.host !== "localhost"
        ? window.location.host
        : "dev.internal.apxor.com",
  })

  return callApi(
    `${INVITEES_API}/new?${queryString.toString()}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Edits the role, flags, and permissions of the user.
 * @param { string } appId The `appId` of the app.
 * @param {EditPermissionsDto} invitation_data The new set of secondary details.
 * @returns {Promise} Returns a promise.
 */
export function editUserPermissionsAPI(appId, invitation_data) {
  const config = {
    method: "PUT",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }

  const queryString = withQueryStrings({
    appId,
    customerId: invitation_data.customer_id,
    role: invitation_data.role ?? ROLES_ENUM.SUPPORT.value,
    flags: invitation_data?.flags ?? [],
    permissions: invitation_data?.permissions ?? [],
  })

  return callApi(
    `customers/settings?${queryString}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Gets the list of all the users of an app.
 * @param { string } appId The `appId` of the app.
 * @returns {Promise} Returns a promise.
 */
export function getAllInviteesAPI(appId) {
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INVITEES_API}?appId=${appId}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Deletes the user from an app.
 * @param { string } appId The `appId` of the app.
 * @param {string} inviteeId The `email` of the user to be deleted.
 * @returns {Promise} Returns a promise.
 */
export function revokeInvitationAPI(appId, inviteeId) {
  const config = {
    method: "DELETE",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INVITEE_API}?appId=${appId}&inviteeId=${inviteeId}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Sets the invitation status of the given user to to a given value.
 * @param {string} token The `token` received via the magic link from email.
 * @param {boolean} status The invitation status to be set to.
 * @returns {Promise} Returns a promise.
 */
export function setInviteeStatusAPI(token, status) {
  const config = {
    method: "PATCH",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INVITEE_API}/status?token=${token}&status=${status}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Checks if the invitation sent to a user is revoked.
 * @param {string} appId The `appId` of the app to which the user was invited.
 * @param {string} inviteeId The `email` of the user.
 * @returns {Promise} Returns a promise.
 */
export function checkInvitationRevokedAPI(appId, inviteeId) {
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INVITEE_API}/revoked?appId=${appId}&inviteeId=${inviteeId}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Sets the `integrated` flag to `true` for the given `appId`.
 * @param {string} appId The `appId` of the app of which the integration field is to be set to `true`.
 * @returns {Promise} Returns a promise.
 */
export function updateIntegrationStatusAPI(appId) {
  const config = {
    method: "PATCH",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `${INTEGRATION_API}/status?appId=${appId}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}
