import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import {
  TrendingUpRounded as AnalyticsActiveIcon,
  TrendingUpOutlined as AnalyticsIcon,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  ColorLensRounded as GlobalThemingActiveIcon,
  ColorLensOutlined as GlobalThemingIcon,
  TouchAppRounded as NudgesActiveIcon,
  TouchAppOutlined as NudgesIcon,
  AssessmentRounded as ReportingActiveIcon,
  AssessmentOutlined as ReportingIcon,
  SearchOutlined,
  SettingsRounded as SettingsActiveIcon,
  SettingsOutlined as SettingsIcon,
  PersonRounded as TargetAudienceActiveIcon,
  PersonOutline as TargetAudienceIcon,
} from "@material-ui/icons"
import ActiveIcon from "@material-ui/icons/FiberManualRecord"
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown"
import classNames from "classnames"
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { NavLink, useLocation, useParams } from "react-router-dom"
import { isSuper } from "../../../../config"
import { darkTheme } from "../../../../styling"
import {
  capitalizeEachWord,
  isFlagEnabledForUser,
  isRouteSetEnabled,
} from "../../../../utils"
import useApxorClient from "../../../../utils/use-apxor-client"
import {
  useDeepCompareCallback,
  useDeepCompareMemoNoCheck,
} from "../../../../utils/use-deep-compare"
import SDKMonitoringDialog from "../../../App/components/SDKMonitoringDialog"
import { APP_MONITORING_ENUM } from "../../../App/constants"
import {
  ADDITIONAL_ROLES_ENUM,
  ALL_PERMISSIONS,
  ROLES_ENUM,
  SETTINGS_PAGES_IDS,
  isCSM,
} from "../../../Dashboard/components/settings/constants"
import {
  FEATURES_LIST,
  FEATURES_LIST_ENUM,
} from "../../../Dashboard/components/settings/general/constants"
import { useTrackedState } from "../../../IntegrationExperience/store"
import { useTrackedState as useOrgState } from "../../../Organizations/store"
import useUser from "../../../Profile/hooks/use-user"
import ColumnDiv from "../../../ReusableComponents/ColumnDiv"
import Snackbar from "../../../ReusableComponents/Snackbar"
import DialogLoader from "../../../ReusableComponents/skeletons/DialogLoader"
import { apxTheme as theme } from "../../styling/CustomCSS"

const drawerWidth = 242

const DASHBOARD_ENDPOINTS = {
  ALERTS: "alerts",
  APPS: "apps",
  APPROVAL: "approve",
  AUDIENCE: "audience",
  CONTEXTS: "contexts",
  COHORTS: "cohorts",
  DYNAMIC_COHORTS: "dynamic-cohorts",
  CREATE: "create",
  CUSTOM_GROUPING: "custom-grouping",
  CUSTOM_REPORTS: "custom-reports",
  CUSTOM_FONTS: "custom-fonts",
  DASHBOARD: "dashboard",
  DASHBOARDS: "dashboards",
  DOWNLOADS: "downloads",
  DROP_OFF: "drop-off",
  ENGAGEMENT_REPORT: "reports/key-metrics",
  EVENT_ANALYSIS: "event-analysis",
  FUNNELS: "funnels",
  THEMES: "themes",
  HEALTH: "health",
  INCIDENTS: "incidents",
  INTEGRATION: "integration",
  ISSUE_DETAILS: "issues",
  LOGIN: "login",
  LOGOUT: "logout",
  MESSAGES: "messages",
  WALKTHROUGHS: "walkthroughs",
  OVERVIEW: "overview",
  PATH: "path",
  PREPROCESS: "preprocess",
  PROFILE: "profile",
  PUSH: "push",
  REPORTS: "reports",
  RESET_PASSWORD: "reset-password",
  RETENTION: "retention",
  SEGMENTS: "segments",
  SETTINGS: "settings",
  SETTINGS_DATA: "settings/downloads",
  SETTINGS_GENERAL: "settings/general",
  SETTINGS_IAM: "settings/iam",
  SETTINGS_EM: "settings/events",
  SETTINGS_SDK: "settings/sdk",
  SETTINGS_SESSIONS: "settings/sessions",
  SETTINGS_TEST_DEVICES: "settings/test-devices",
  SETTINGS_USERS: "settings/users",
  SETTINGS_MANAGE_PLAN: "settings/manage-plan",
  SIGN_UP: "sign_up",
  SURVEYS: "surveys",
  UNAUTHORISED: "unauthorised",
  UNINSTALL_REPORT: "reports/day0-analysis",
  UNINSTALLS: "uninstalls",
  USER: "user",
  USER_EXPLORER: "user-explorer",
  USERS: "users",
  REQUESTS: "requests",
}

const styles = () => ({
  appDropdown: { height: 92 },
  profileDropdown: { height: 100 },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 16px",
    ...theme.mixins.toolbar,
  },
  appIcon: {
    width: 40,
  },
  profileIcon: {
    width: 32,
    borderRadius: 32,
  },
  sideLogo: {
    justifyContent: "flex-start",
    width: "65%",
  },
  sideLogoDrawerClosed: {
    height: 40,
  },
  drawerPaper: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    position: "fixed",
    zIndex: 10000,
    height: "100vh",
    width: drawerWidth,
    transition: "all 0.1s ease-in-out",
    overflowY: "auto",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperOpen: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    minWidth: 242,
    transition: "all 0.1s ease-in-out",
  },
  drawerPaperClosed: {
    maxWidth: "90px !important",
    overflowX: "hidden",
  },
  nested: {
    paddingLeft: "52px !important",
  },
  activeNode: {
    borderLeft: `6px solid ${theme.palette.primary.main}`,
    padding: "8px 12px",
    height: 48,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  node: {
    borderLeft: "6px solid transparent",
    padding: "8px 12px",
    height: 48,
    transition: "all 0.1s ease-in-out",
  },
  drawerClosedNode: {
    paddingLeft: "27px !important",
  },
  nodeLeft: {
    alignItems: "center",
  },
  listItemIcon: {
    minWidth: "auto",
    paddingRight: 12,
  },
  listItemIconActive: {
    minWidth: "auto",
    paddingRight: 12,
    color: theme.palette.primary.main,
  },
  listItemText: {
    fontSize: 14,
    fontWeight: "600",
    color: theme.palette.secondary.main,
  },
  listItemTextActive: {
    fontSize: 14,
    fontWeight: "600",
    color: theme.palette.primary.main,
  },

  activeDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.success.main,
  },
  inactiveDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.error.main,
  },
  sidebarVerticalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
})

const navigationLink = (orgId, appId, endPoint) => {
  return { pathname: `/orgs/${orgId}/apps/${appId}/${endPoint}` }
}

const ProfileLabel = ({ children }) => (
  <Typography
    style={{
      fontWeight: 700,
      padding: "2px 7px",
      background: "#176FAC",
      borderRadius: 9999,
      color: "white",
      fontSize: 9,
      width: "fit-content",
    }}
  >
    {children}
  </Typography>
)

const ParentNode = ({
  appState,
  nodeOpen,
  handleOpen,
  classes,
  children,
  label: parentLabel,
  name: parentName,
  id: parentId,
  icon: parentIcon,
  activeIcon: parentActiveIcon,
  path: parentPath,
  drawerOpen,
}) => {
  const { currentRefinedUser } = useUser()

  const { orgId } = useParams()

  const handleClick = () => {
    handleOpen(parentId === nodeOpen ? null : parentId)
    logEvent("LP_CTA_Clicked", { cta_name: parentName })
  }
  const { pathname } = useLocation()
  const isCurrentActive = checkIfParentIsActive(parentId, pathname)

  const {
    app_data: { features = [] },
  } = useTrackedState()

  const isFeatureEnabledForOrg = useDeepCompareCallback(
    (featureName) => features.includes(featureName),
    [features],
  )

  const { logEvent } = useApxorClient()

  const showNavItem = useCallback(
    (childLabel) => {
      return (
        isFeatureEnabledForOrg(
          FEATURES_LIST_ENUM[parentLabel]?.children[childLabel]?.value,
        ) &&
        isRouteSetEnabled(
          currentRefinedUser,
          FEATURES_LIST_ENUM[parentLabel]?.children[childLabel]?.value,
        )
      )
    },
    [currentRefinedUser, isFeatureEnabledForOrg, parentLabel],
  )

  if (!children) {
    return (
      <NavLink to={navigationLink(orgId, appState?.app_id, parentPath)}>
        <ListItem
          disableGutters
          button
          className={classNames({
            [classes.activeNode]: isCurrentActive,
            [classes.node]: !isCurrentActive,
            [classes.drawerClosedNode]: !drawerOpen,
          })}
        >
          <ListItemIcon
            className={classNames({
              [classes.listItemIcon]: true,
              [classes.listItemIconActive]: isCurrentActive,
            })}
          >
            {isCurrentActive ? parentActiveIcon : parentIcon}
          </ListItemIcon>
          {drawerOpen && (
            <ListItemText
              className={classes.listItemText}
              primary={parentName}
              primaryTypographyProps={{
                className: classes.listItemText,
              }}
            />
          )}
        </ListItem>
      </NavLink>
    )
  } else {
    const childNodes = children.map(({ label, name, id, path }) => {
      const pathArray = pathname?.split("/")
      const isChildActive =
        pathArray[pathArray?.length - 1] === id && isCurrentActive
      return (
        showNavItem(label) && (
          <NavLink
            onClick={() => {
              logEvent("LP_CTA_Clicked", {
                cta_name: name,
              })
            }}
            to={navigationLink(orgId, appState?.app_id, path)}
          >
            <List
              component="div"
              disablePadding
              key={id}
            >
              <ListItem
                disableGutters
                button
                className={classes.nested}
              >
                <ListItemText
                  className={classNames({
                    [classes.listItemTextActive]: isChildActive,
                    [classes.listItemText]: !isChildActive,
                  })}
                  primary={name}
                  primaryTypographyProps={{
                    className:
                      classes[
                        isChildActive ? "listItemTextActive" : "listItemText"
                      ],
                  }}
                />
              </ListItem>
            </List>
          </NavLink>
        )
      )
    })

    const isOpen = nodeOpen === parentId

    return (
      <>
        <ListItem
          disableGutters
          button
          onClick={handleClick}
          className={classNames({
            [classes.activeNode]: isCurrentActive,
            [classes.node]: !isCurrentActive,
            [classes.drawerClosedNode]: !drawerOpen,
          })}
        >
          <ListItemIcon
            className={classNames({
              [classes.listItemIcon]: true,
              [classes.listItemIconActive]: isCurrentActive,
            })}
          >
            {parentIcon}
          </ListItemIcon>
          {drawerOpen && (
            <>
              <ListItemText
                className={classes.listItemText}
                primaryTypographyProps={{
                  className: classes.listItemText,
                }}
              >
                {parentName}
              </ListItemText>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
        >
          {childNodes}
        </Collapse>
      </>
    )
  }
}

const navigationItems = [
  {
    label: "NUDGES",
    name: "Nudges",
    id: "nudges",
    path: `${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    icon: <NudgesIcon />,
    activeIcon: <NudgesActiveIcon />,
    children: [
      {
        label: "DASHBOARD",
        name: "Overview",
        id: "dashboard",
        path: `${DASHBOARD_ENDPOINTS.DASHBOARD}`,
        icon: null,
        children: null,
      },
      {
        label: "CAMPAIGNS",
        name: "Campaigns",
        id: "walkthroughs",
        path: `${DASHBOARD_ENDPOINTS.WALKTHROUGHS}`,
        icon: null,
        children: null,
      },
      {
        label: "SURVEYS",
        name: "Surveys",
        id: "surveys",
        path: `${DASHBOARD_ENDPOINTS.SURVEYS}`,
        icon: null,
        children: null,
      },
      {
        label: "PUSH",
        name: "Push",
        id: "push",
        path: `${DASHBOARD_ENDPOINTS.PUSH}`,
        icon: null,
        children: null,
      },
    ],
  },
  {
    label: "ANALYTICS",
    name: "Analytics",
    id: "analytics",
    path: "analytics",
    icon: <AnalyticsIcon />,
    activeIcon: <AnalyticsActiveIcon />,
    children: [
      {
        label: "TEMPLATES_RETENTION",
        name: "Retention",
        id: "retention",
        path: `${DASHBOARD_ENDPOINTS.RETENTION}`,
        icon: null,
        children: null,
      },
      {
        label: "UNINSTALLS",
        name: "Uninstalls",
        id: "uninstalls",
        path: `${DASHBOARD_ENDPOINTS.UNINSTALLS}`,
        icon: null,
        children: null,
      },
      {
        label: "FUNNELS",
        name: "Funnels",
        id: "funnels",
        path: `${DASHBOARD_ENDPOINTS.FUNNELS}`,
        icon: null,
        children: null,
      },
      {
        label: "PATHS",
        name: "Paths",
        id: "path",
        path: `${DASHBOARD_ENDPOINTS.PATH}`,
        icon: null,
        children: null,
      },
    ],
  },
  {
    label: "REPORTING",
    name: "Reporting",
    id: "reporting",
    path: `${DASHBOARD_ENDPOINTS.REPORTING}`,
    icon: <ReportingIcon />,
    activeIcon: <ReportingActiveIcon />,
    children: [
      {
        label: "CUSTOM_REPORTS",
        name: "Custom Reports",
        id: "custom-reports",
        path: `${DASHBOARD_ENDPOINTS.CUSTOM_REPORTS}`,
        icon: null,
        children: null,
      },
    ],
  },
  {
    label: "EXPLORER",
    name: "Explorer",
    id: "explorer",
    path: `${DASHBOARD_ENDPOINTS.EVENT_ANALYSIS}`,
    icon: <SearchOutlined />,
    children: [
      {
        label: "EVENT_EXPLORER",
        name: "Event Explorer",
        id: "event-analysis",
        path: `${DASHBOARD_ENDPOINTS.EVENT_ANALYSIS}`,
        icon: null,
        children: null,
      },
      {
        label: "USER_EXPLORER",
        name: "User Explorer",
        id: "user-explorer",
        path: `${DASHBOARD_ENDPOINTS.USER_EXPLORER}`,
        icon: null,
        children: null,
      },
    ],
  },
  {
    label: "TARGET_AUDIENCE",
    name: "Target Audience",
    id: "target_audience",
    path: `${DASHBOARD_ENDPOINTS.TARGET_AUDIENCE}`,
    icon: <TargetAudienceIcon />,
    activeIcon: <TargetAudienceActiveIcon />,
    children: [
      {
        label: "COHORTS",
        name: "Cohorts",
        id: "cohorts",
        path: `${DASHBOARD_ENDPOINTS.COHORTS}`,
        icon: null,
        children: null,
      },
      {
        label: "DYNAMIC_COHORTS",
        name: "Dynamic Cohorts",
        id: "dynamic-cohorts",
        path: `${DASHBOARD_ENDPOINTS.DYNAMIC_COHORTS}`,
        icon: null,
        children: null,
      },
      {
        label: "SEGMENTS",
        name: "Segments",
        id: "segments",
        path: `${DASHBOARD_ENDPOINTS.SEGMENTS}`,
        icon: null,
        children: null,
      },
    ],
  },
  {
    label: "THEMES",
    name: "Themes",
    id: "themes",
    path: `${DASHBOARD_ENDPOINTS.THEMES}`,
    activeIcon: <GlobalThemingActiveIcon />,
    icon: <GlobalThemingIcon />,
    children: [
      {
        label: "GLOBAL_THEMES",
        name: "Campaigns",
        id: "walkthroughs",
        path: `${DASHBOARD_ENDPOINTS.THEMES}/${DASHBOARD_ENDPOINTS.WALKTHROUGHS}`,
        icon: null,
        children: null,
      },
      {
        label: "GLOBAL_THEMES",
        name: "Surveys",
        id: "surveys",
        path: `${DASHBOARD_ENDPOINTS.THEMES}/${DASHBOARD_ENDPOINTS.SURVEYS}`,
        icon: null,
        children: null,
      },
      {
        label: "CUSTOM_FONTS",
        name: "Custom Fonts",
        id: "custom-fonts",
        path: `${DASHBOARD_ENDPOINTS.THEMES}/${DASHBOARD_ENDPOINTS.CUSTOM_FONTS}`,
        icon: null,
        children: null,
      },
    ],
  },
  {
    label: "SETTINGS",
    name: "Settings",
    id: "settings",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_IAM}`,
    icon: <SettingsIcon />,
    activeIcon: <SettingsActiveIcon />,
    children: null,
  },
]

const checkIfParentIsActive = (parentId, pathname) => {
  if (
    parentId === "settings" &&
    SETTINGS_PAGES_IDS.find((item) => pathname.includes(item)) !== undefined
  ) {
    return true
  } else if (
    parentId === "themes" &&
    pathname.includes(parentId) &&
    (pathname.includes("surveys") ||
      pathname.includes("walkthroughs") ||
      pathname.includes("custom-fonts"))
  ) {
    return true
  } else if (
    !pathname.includes("themes") &&
    (parentId !== "settings" || parentId !== "themes")
  ) {
    const parent = navigationItems.find((item) =>
      item.children?.find((child) => pathname.includes(child.id)),
    )

    return parent?.id === parentId
  }
}

const NavigationNew = ({
  open: drawerOpen,
  appState,
  user,
  handleMouseEnter,
  handleMouseLeave,
  classes,
}) => {
  const { orgId, appId } = useParams()
  const { pathname } = useLocation()

  const {
    org_data: { owner },
  } = useOrgState()

  const [nodeOpen, setNodeOpen] = useState(null)
  const [isOpenProfileDropdown, setOpenProfileDropdown] = useState(false)
  const [appActive, setAppActive] = useState(appState?.monitoring)
  const { appFeaturesArr, currentRefinedUser } = useUser()
  const {
    app_data: { features = [] },
    app_loading,
  } = useTrackedState()

  const profileItems = useDeepCompareMemoNoCheck(() => {
    let profileTabs = [
      {
        name: "My Profile",
        id: "profile",
        path: `${DASHBOARD_ENDPOINTS.PROFILE}`,
        icon: null,
        children: null,
      },
      {
        name: "Logout",
        id: "logout",
        path: `${DASHBOARD_ENDPOINTS.LOGOUT}`,
        icon: <ExitToApp />,
        children: null,
      },
    ]

    if (
      appFeaturesArr?.includes(
        FEATURES_LIST_ENUM.NUDGES.children.WORKFLOW_APPROVAL.value,
      ) &&
      currentRefinedUser?.flags?.includes(
        FEATURES_LIST_ENUM.NUDGES.children.WORKFLOW_APPROVAL.value,
      )
    ) {
      profileTabs.unshift({
        name: "Requests",
        id: `orgs/${orgId}/apps/${appId}/requests`,
        path: `${DASHBOARD_ENDPOINTS.REQUESTS}`,
        icon: null,
        children: null,
      })
    }

    return profileTabs
  }, [appFeaturesArr, appId, currentRefinedUser?.flags, orgId])

  const { allFeaturesArr } = useUser()

  useEffect(() => {
    if (!drawerOpen) {
      setNodeOpen(null)
      setOpenProfileDropdown(false)
    }

    if (!app_loading) {
      setAppActive(appState?.monitoring)
    }
  }, [appState.monitoring, app_loading, drawerOpen])

  const handleSetNodeOpen = (nodeId) => setNodeOpen(nodeId)
  const handleOpenProfileDropdown = () =>
    setOpenProfileDropdown((prev) => !prev)

  const currentUserFromAppState = useMemo(() => {
    if (owner === user?.email || isSuper(user?.email)) {
      return {
        customer_id: user?.email,
        role: {
          name: ROLES_ENUM.ORG_OWNER.name,
          color: ROLES_ENUM.ORG_OWNER.color,
        },
        flags: allFeaturesArr,
        permissions: ALL_PERMISSIONS,
      }
    } else {
      let furnishedUser = appState?.basic_info?.customers?.find(
        (customer) => customer?.customer_id === user?.email,
      )

      if (isCSM(furnishedUser?.role, furnishedUser?.customer_id, orgId)) {
        furnishedUser = {
          ...furnishedUser,
          role: {
            name: "Customer Success Manager",
            color: ADDITIONAL_ROLES_ENUM.CSM.color,
          },
        }
      } else if (
        furnishedUser?.role === ROLES_ENUM.PM.value &&
        furnishedUser?.flags?.filter(
          (flag) => flag !== FEATURES_LIST_ENUM.NUDGES.children.AB.value,
        )[0] === FEATURES_LIST_ENUM.SETTINGS.children.IAM.value &&
        furnishedUser?.flags?.length === 1
      ) {
        furnishedUser = {
          ...furnishedUser,
          role: {
            name: "User Access Manager",
            color:
              Object.values(ROLES_ENUM).find(
                (role) => role.value === furnishedUser?.role,
              )?.color ?? ROLES_ENUM.SUPPORT.color,
          },
        }
      } else {
        furnishedUser = {
          ...furnishedUser,
          role: {
            name:
              Object.values(ROLES_ENUM).find(
                (role) => role.value === furnishedUser?.role,
              )?.name ?? ROLES_ENUM.SUPPORT.name,
            color:
              Object.values(ROLES_ENUM).find(
                (role) => role.value === furnishedUser?.role,
              )?.color ?? ROLES_ENUM.SUPPORT.color,
          },
        }
      }

      return furnishedUser
    }
  }, [
    owner,
    user?.email,
    allFeaturesArr,
    appState?.basic_info?.customers,
    orgId,
  ])

  const isFeatureEnabledForOrg = useDeepCompareCallback(
    (parentName) => {
      return FEATURES_LIST[parentName].some((feature) =>
        features.includes(feature),
      )
    },
    [features],
  )

  const [showMonitoringToggleDialog, setShowMonitoringToggleDialog] =
    useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")

  const { logEvent } = useApxorClient()

  return (
    <>
      {!app_loading && snackBarMessage !== "" && (
        <Snackbar
          onClose={() => {
            setSnackBarMessage("")
          }}
        >
          <strong>{snackBarMessage}</strong>
        </Snackbar>
      )}
      <MuiThemeProvider theme={darkTheme}>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          className={classNames(classes.drawerPaper, {
            [classes.drawerPaperOpen]: drawerOpen,
            [classes.drawerPaperClosed]: !drawerOpen,
          })}
          classes={{
            paper: classNames({
              [classes.drawerPaperOpen]: drawerOpen,
              [classes.drawerPaperClosed]: !drawerOpen,
            }),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={classes.sidebarVerticalContainer}>
            <div>
              <div
                className={classNames(classes.drawerHeader, {
                  [classes.drawerClosedNode]: !drawerOpen,
                })}
              >
                <NavLink
                  to={`/orgs/${orgId}/apps`}
                  onClick={() => {
                    logEvent("Apps_PageLaunched", {
                      source: "Home-icon-clicked",
                    })
                  }}
                >
                  <img
                    alt={window.apxorAppInfo.whiteLogo}
                    src={
                      drawerOpen
                        ? "/assets/img/logo-white.png"
                        : "/assets/img/sidebar_logo.png"
                    }
                    className={classNames({
                      [classes.sideLogo]: drawerOpen,
                      [classes.sideLogoDrawerClosed]: !drawerOpen,
                    })}
                  />
                </NavLink>
              </div>
              <ListItem
                className={classNames(classes.appDropdown, {
                  [classes.drawerClosedNode]: !drawerOpen,
                })}
              >
                {appState?.basic_info?.icon_url && (
                  <ListItemIcon>
                    <img
                      alt=""
                      src={appState?.basic_info?.icon_url}
                      className={classes.appIcon}
                    />
                  </ListItemIcon>
                )}
                {drawerOpen && (
                  <ListItemText
                    primary={appState?.basic_info?.app_name}
                    secondary={
                      <Tooltip
                        title="Toggle SDK Monitoring"
                        placement="right"
                        arrow
                      >
                        <Box
                          style={{
                            marginTop: "4px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "24px",
                            maxWidth:
                              isSuper(user?.email) || owner === user?.email
                                ? "110px"
                                : "75px",
                            gap: "4px",
                            border: `1px solid ${
                              appActive
                                ? APP_MONITORING_ENUM.ACTIVE.glassColor
                                : APP_MONITORING_ENUM.INACTIVE.glassColor
                            }`,
                            borderRadius: "12px",
                            padding: "3px 8px",
                            color: "#fff",
                            backgroundColor: appActive
                              ? APP_MONITORING_ENUM.ACTIVE.glassColor
                              : APP_MONITORING_ENUM.INACTIVE.glassColor,
                            pointerEvents:
                              isSuper(user?.email) || owner === user?.email
                                ? "auto"
                                : "none",
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (isSuper(user?.email) || owner === user?.email) {
                              setShowMonitoringToggleDialog(true)
                            }
                          }}
                        >
                          <ActiveIcon
                            style={{
                              width: "10px",
                              height: "10px",
                            }}
                          />
                          <Typography
                            style={{
                              fontWeight: 700,
                              flex: "1",
                              fontSize: "12px",
                              color: "#fff",
                            }}
                          >
                            {appActive ? "Active" : "InActive"}
                          </Typography>
                          {(isSuper(user?.email) || owner === user?.email) && (
                            <ChevronDownIcon
                              fontSize="small"
                              style={{
                                color: "#fff",
                                padding: "0px",
                              }}
                            />
                          )}
                        </Box>
                      </Tooltip>
                    }
                    primaryTypographyProps={{
                      style: {
                        fontWeight: 600,
                      },
                    }}
                  />
                )}
              </ListItem>
              <List component="nav">
                {navigationItems?.map((parentNode, idx) => (
                  <Fragment key={parentNode?.id}>
                    {[FEATURES_LIST_ENUM.SETTINGS.id.toLowerCase()].includes(
                      parentNode.id,
                    ) ? (
                      <ParentNode
                        key={`${parentNode.id}-${idx}`}
                        appState={appState}
                        {...parentNode}
                        nodeOpen={nodeOpen}
                        handleOpen={handleSetNodeOpen}
                        classes={classes}
                        drawerOpen={drawerOpen}
                      />
                    ) : (
                      <>
                        {isFeatureEnabledForOrg(parentNode.name) &&
                          isFlagEnabledForUser(
                            currentUserFromAppState,
                            parentNode.name,
                          ) && (
                            <ParentNode
                              key={`${parentNode.id}-${idx}`}
                              appState={appState}
                              {...parentNode}
                              nodeOpen={nodeOpen}
                              handleOpen={handleSetNodeOpen}
                              classes={classes}
                              drawerOpen={drawerOpen}
                            />
                          )}
                      </>
                    )}
                  </Fragment>
                ))}
              </List>
            </div>
            <ColumnDiv
              styles={{
                marginBottom: "20px",
                gap: "5px",
              }}
            >
              <ListItem
                button
                onClick={handleOpenProfileDropdown}
                className={classNames(classes.profileDropdown, {
                  [classes.drawerClosedNode]: !drawerOpen,
                })}
              >
                <ListItemIcon>
                  <Avatar
                    style={{
                      color: theme.palette.secondary.main,
                      border: "2px solid",
                      borderColor: theme.palette.primary.main,
                      backgroundColor: "transparent",
                      fontWeight: 500,
                    }}
                  >
                    {capitalizeEachWord(user?.[user?.name ? "name" : "email"])
                      ?.split(" ")
                      .map((name) => name[0])
                      .slice(0, 2)
                      .join("")}
                  </Avatar>
                </ListItemIcon>
                {drawerOpen && (
                  <>
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontWeight: 600,
                            maxWidth: 120,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user?.name ?? user?.email}
                        </Typography>
                      }
                      secondary={
                        <ColumnDiv styles={{ gap: 4, maxWidth: 120 }}>
                          <Tooltip
                            title={user?.email}
                            placement="top"
                            arrow
                          >
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              style={{
                                fontWeight: 500,
                                maxWidth: 120,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {user?.email}
                            </Typography>
                          </Tooltip>
                          <ProfileLabel>
                            {currentUserFromAppState?.role?.name}
                          </ProfileLabel>
                        </ColumnDiv>
                      }
                    />
                    {isOpenProfileDropdown ? <ExpandLess /> : <ExpandMore />}
                  </>
                )}
              </ListItem>

              <Collapse
                in={isOpenProfileDropdown}
                timeout="auto"
                unmountOnExit
              >
                {profileItems.map(({ name, id, icon }) => {
                  const isCurrentActive = id === pathname.split("/").at(-1)

                  return (
                    <List
                      component="div"
                      disablePadding
                      key={id}
                    >
                      <ListItem
                        disableGutters
                        button
                        className={classes.nested}
                        component={NavLink}
                        to={`/${id}`}
                      >
                        <ListItemText
                          className={classNames({
                            [classes.listItemTextActive]: isCurrentActive,
                            [classes.listItemText]: !isCurrentActive,
                          })}
                          primary={name}
                          primaryTypographyProps={{
                            className:
                              classes[
                                isCurrentActive
                                  ? "listItemTextActive"
                                  : "listItemText"
                              ],
                          }}
                        />
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                      </ListItem>
                    </List>
                  )
                })}
              </Collapse>
            </ColumnDiv>
          </div>
        </Drawer>
      </MuiThemeProvider>

      {/* SDK Monitoring Dialog */}
      {showMonitoringToggleDialog && (
        <Suspense fallback={<DialogLoader />}>
          <SDKMonitoringDialog
            open={showMonitoringToggleDialog}
            onClose={() => {
              setShowMonitoringToggleDialog(false)
            }}
            enabled={appActive}
            setMonitoring={setAppActive}
          />
        </Suspense>
      )}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(NavigationNew)
