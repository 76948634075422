import {
  Avatar,
  Box,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core"
import ErrorIcon from "@material-ui/icons/Error"
import ActiveIcon from "@material-ui/icons/FiberManualRecord"
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { AvatarGroup } from "@material-ui/lab"
import { IconCopy, IconDotsVertical, IconTrash } from "@tabler/icons-react"
import { Suspense, lazy, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "sonner"
import { isSuper } from "../../../config"
import { DASHBOARD_ENDPOINTS } from "../../../constants/Endpoints/index"
import { useAuth } from "../../../contexts/AuthContext"
import { copyToClipboard } from "../../../utils"
import useApxorClient from "../../../utils/use-apxor-client"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import { APP_PLATFORM_NAMES } from "../../Dashboard/common/components/campaigncreator/components/CreateEditCampaignDialog"
import {
  ALL_PERMISSIONS,
  ROLES_ENUM,
} from "../../Dashboard/components/settings/constants"
import { FEATURES_LIST_ENUM } from "../../Dashboard/components/settings/general/constants"
import {
  StyledMenu,
  StyledMenuButton,
} from "../../Organizations/components/OrgCard"
import {
  useTrackedState as useOrgState,
  useTrackedState,
} from "../../Organizations/store"
import useUser from "../../Profile/hooks/use-user"
import HStack from "../../ReusableComponents/HStack"
import ThemedTooltip from "../../ReusableComponents/ThemedTooltip"
import VStack from "../../ReusableComponents/VStack"
import WhitePaper from "../../ReusableComponents/WhitePaper"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"
import { APP_DIALOG_STATES_ENUM, APP_MONITORING_ENUM } from "../constants"
import { PLATFORM_IMAGES, getPlatformLogoColor } from "../styling/CustomCSS"

const SDKMonitoringDialog = lazy(() => import("./SDKMonitoringDialog"))
const DeleteAppDialog = lazy(() => import("./DeleteAppDialog"))

const colorList = [
  "#311B92",
  "#304FFE",
  "#0091EA",
  "#1B5E20",
  "#D50000",
  "#C51162",
  "#82B1FF",
  "#AA00FF",
]

const StyledAvatarGroup = withStyles({
  avatar: {
    border: "0px",
    width: apxTheme.spacing(3),
    height: apxTheme.spacing(3),
    fontWeight: "600",
    fontSize: "0.6rem",
  },
})(AvatarGroup)

const StyledAppCard = withStyles({
  root: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    padding: "20px 25px",
    minHeight: "220px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      boxShadow: "0px 1px 40px rgba(181, 202, 224, 0.4)",
    },
  },
})(Box)

const StyledSkeletonBox = withStyles({
  "@keyframes pulse": {
    "50%": {
      opacity: "0.55",
    },
  },
  root: {
    animation: "$pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite",
  },
})(Box)

export function AppCardSkeleton() {
  return (
    <StyledAppCard component={WhitePaper}>
      <HStack
        spacing="16px"
        align="start"
      >
        <StyledSkeletonBox
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E0E0E0",
          }}
        ></StyledSkeletonBox>
        <StyledSkeletonBox
          style={{
            backgroundColor: "#E0E0E0",
            padding: "10px",
            borderRadius: "3px",
            width: "100px",
          }}
        ></StyledSkeletonBox>
      </HStack>
      <HStack
        spacing="40px"
        align="start"
      >
        {[...Array(2)].map((_, idx) => (
          <VStack
            spacing="6px"
            key={idx}
          >
            <StyledSkeletonBox
              style={{
                backgroundColor: "#E0E0E0",
                padding: "10px",
                borderRadius: "3px",
                width: "26px",
              }}
            ></StyledSkeletonBox>
            <StyledSkeletonBox
              style={{
                backgroundColor: "#E0E0E0",
                padding: "15px 10px",
                borderRadius: "3px",
                width: "60px",
              }}
            ></StyledSkeletonBox>
          </VStack>
        ))}
      </HStack>
    </StyledAppCard>
  )
}

export default function AppCard({ app }) {
  const { push } = useHistory()
  const {
    user,
    user: { email },
  } = useAuth()

  const { allFeaturesArr } = useUser()

  const { orgId } = useParams()
  const { org_data } = useTrackedState()
  const [monitoring, setMonitoring] = useState(app?.monitoring ?? false)

  const [cardHovered, setCardHovered] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    org_data: { owner },
  } = useOrgState()

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const currentUserFromAppState = useMemo(() => {
    if (owner === email || isSuper(email)) {
      return {
        customer_id: email,
        role: ROLES_ENUM.ORG_OWNER.value,
        flags: allFeaturesArr,
        permissions: ALL_PERMISSIONS,
      }
    } else {
      return app?.basic_info?.customers?.find(
        (customer) => customer?.customer_id === email,
      )
    }
  }, [allFeaturesArr, app?.basic_info?.customers, email, owner])

  const [dialogState, setDialogState] = useState(APP_DIALOG_STATES_ENUM.CLOSE)

  const appStatus = useMemo(() => {
    if (app?.integrated) {
      if (app?.basic_info?.approved) return null
      else return "Approval Pending"
    } else return "Integration Pending"
  }, [app?.basic_info?.approved, app?.integrated])

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        handleClose(event)
      }
    }
    window.addEventListener("mouseout", handleMouseOut)

    return () => {
      window.removeEventListener("mouseout", handleMouseOut)
    }
  }, [])

  const { logEvent } = useApxorClient()

  return (
    <>
      <StyledAppCard
        component={WhitePaper}
        style={{
          position: "relative",
        }}
        onClick={() => {
          const userFlagsArr = currentUserFromAppState?.flags ?? []

          const uamUser = userFlagsArr?.filter(
            (flag) => flag !== FEATURES_LIST_ENUM.NUDGES.children.AB.value,
          )

          if (
            uamUser &&
            uamUser?.length === 1 &&
            uamUser[0] === FEATURES_LIST_ENUM.SETTINGS.children.IAM.value
          ) {
            push(
              `/orgs/${orgId}/apps/${app?.app_id}/${DASHBOARD_ENDPOINTS.SETTINGS_IAM}`,
            )
          } else if (app?.integrated) {
            if (
              !userFlagsArr?.includes(
                FEATURES_LIST_ENUM.NUDGES.children.DASHBOARD.value,
              ) &&
              (userFlagsArr?.includes(
                FEATURES_LIST_ENUM.NUDGES.children.CAMPAIGNS.value,
              ) ||
                userFlagsArr?.includes(
                  FEATURES_LIST_ENUM.NUDGES.children.SURVEYS.value,
                ))
            ) {
              if (
                userFlagsArr?.includes(
                  FEATURES_LIST_ENUM.NUDGES.children.CAMPAIGNS.value,
                )
              ) {
                push(
                  `/orgs/${orgId}/apps/${app?.app_id}/${DASHBOARD_ENDPOINTS.WALKTHROUGHS}`,
                )
              } else {
                push(
                  `/orgs/${orgId}/apps/${app?.app_id}/${DASHBOARD_ENDPOINTS.SURVEYS}`,
                )
              }
            } else if (
              userFlagsArr?.includes(
                FEATURES_LIST_ENUM.NUDGES.children.DASHBOARD.value,
              ) &&
              (userFlagsArr?.includes(
                FEATURES_LIST_ENUM.NUDGES.children.CAMPAIGNS.value,
              ) ||
                userFlagsArr?.includes(
                  FEATURES_LIST_ENUM.NUDGES.children.SURVEYS.value,
                ))
            ) {
              logEvent("App_Overview_PageLaunched")
              push(
                `/orgs/${orgId}/apps/${app?.app_id}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
              )
            } else if (
              !(
                userFlagsArr?.includes(
                  FEATURES_LIST_ENUM.NUDGES.children.CAMPAIGNS.value,
                ) ||
                userFlagsArr?.includes(
                  FEATURES_LIST_ENUM.NUDGES.children.SURVEYS.value,
                )
              )
            ) {
              push(
                `/orgs/${orgId}/apps/${app?.app_id}/${DASHBOARD_ENDPOINTS.SETTINGS_IAM}`,
              )
            }
            logEvent("Apps_AppClicked")
          } else {
            push(`/orgs/${orgId}/apps/${app?.app_id}/integrate`)
          }
        }}
        onMouseEnter={() => {
          setCardHovered(true)
        }}
        onMouseLeave={() => {
          setCardHovered(false)
        }}
      >
        {cardHovered && (
          <>
            {/* App Actions Menu Trigger */}
            <IconButton
              style={{
                position: "absolute",
                top: "12px",
                right: "8px",
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleClick(e)
              }}
            >
              <IconDotsVertical
                color={apxTheme.palette.action.active}
                size={18}
              />
            </IconButton>

            {/* App Actions Menu */}
            <StyledMenu
              elevation={0}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* Copy App ID Button */}
              <StyledMenuButton
                onClick={(e) => {
                  copyToClipboard(app?.app_id)
                  handleClose(e)
                  toast.success("App ID copied to clipboard successfully!")
                }}
                startIcon={
                  <IconCopy
                    color={apxTheme.palette.text.primary}
                    size={18}
                  />
                }
              >
                Copy App ID
              </StyledMenuButton>

              {/* Delete App Button */}
              {(isSuper(user?.email) || org_data?.owner === user?.email) && (
                <StyledMenuButton
                  onClick={(e) => {
                    e.stopPropagation()
                    setDialogState(APP_DIALOG_STATES_ENUM.DELETE)
                    handleClose(e)
                  }}
                  startIcon={
                    <IconTrash
                      color={apxTheme.palette.error.main}
                      size={18}
                    />
                  }
                >
                  Delete App
                </StyledMenuButton>
              )}
            </StyledMenu>
          </>
        )}

        <HStack
          spacing="16px"
          align="start"
        >
          <Box
            style={{
              maxWidth: "60px",
            }}
          >
            <img
              src={app?.basic_info?.icon_url}
              height="60px"
              width="60px"
              alt={app?.basic_info?.app_name}
            />
          </Box>
          <VStack spacing="6px">
            <Typography
              style={{
                color: "#002845",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {app?.basic_info?.app_name}
            </Typography>
            <ThemedTooltip
              title="Toggle SDK Monitoring"
              placement="right"
              dark
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "24px",
                  maxWidth:
                    isSuper(user?.email) || org_data?.owner === user?.email
                      ? "110px"
                      : "75px",
                  gap: "4px",
                  border: `1px solid ${
                    monitoring
                      ? APP_MONITORING_ENUM.ACTIVE.glassColor
                      : APP_MONITORING_ENUM.INACTIVE.glassColor
                  }`,
                  borderRadius: "12px",
                  padding: "3px 8px",
                  backgroundColor: monitoring
                    ? APP_MONITORING_ENUM.ACTIVE.backgroundColor
                    : APP_MONITORING_ENUM.INACTIVE.backgroundColor,
                  color: monitoring
                    ? APP_MONITORING_ENUM.ACTIVE.color
                    : APP_MONITORING_ENUM.INACTIVE.color,
                  pointerEvents:
                    isSuper(user?.email) || org_data?.owner === user?.email
                      ? "auto"
                      : "none",
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isSuper(user?.email) || org_data?.owner === user?.email) {
                    setDialogState(APP_DIALOG_STATES_ENUM.MONITORING)
                  }
                }}
              >
                <ActiveIcon
                  style={{
                    width: "10px",
                    height: "10px",
                  }}
                />
                <Typography
                  style={{
                    flex: "1",
                    fontSize: "12px",
                    fontWeight: "700",
                    color: monitoring
                      ? APP_MONITORING_ENUM.ACTIVE.color
                      : APP_MONITORING_ENUM.INACTIVE.color,
                  }}
                >
                  {monitoring ? "Active" : "InActive"}
                </Typography>
                {(isSuper(user?.email) || org_data?.owner === user?.email) && (
                  <ChevronDownIcon
                    fontSize="small"
                    style={{
                      color: monitoring
                        ? APP_MONITORING_ENUM.ACTIVE.color
                        : APP_MONITORING_ENUM.INACTIVE.color,
                      padding: "0px",
                    }}
                  />
                )}
              </Box>
            </ThemedTooltip>
          </VStack>
        </HStack>
        <HStack
          align="end"
          justify="space-between"
          style={{
            width: "100%",
          }}
        >
          <HStack
            align="start"
            spacing="40px"
          >
            <VStack spacing="6px">
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(0, 40, 69, 0.7)",
                }}
              >
                Platform
              </Typography>
              <HStack
                spacing="6px"
                style={{
                  alignSelf: "flex-end",
                }}
              >
                {PLATFORM_IMAGES(
                  app?.basic_info?.platform,
                  getPlatformLogoColor(app?.basic_info?.platform),
                  24,
                )}
                <Typography
                  styles={{
                    color: "#002845",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {APP_PLATFORM_NAMES[app?.basic_info?.platform]}
                </Typography>
              </HStack>
            </VStack>
            <VStack spacing="6px">
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(0, 40, 69, 0.7)",
                }}
              >
                Members
              </Typography>
              <StyledAvatarGroup max={5}>
                {app?.basic_info?.customers?.map(({ customer_id }, idx) => (
                  <ThemedTooltip
                    dark
                    title={
                      customer_id +
                      (customer_id === user?.email ? " (You)" : "")
                    }
                    key={idx + "_" + customer_id}
                  >
                    <Avatar
                      style={{
                        backgroundColor: colorList[idx % colorList.length],
                      }}
                    >
                      {customer_id?.split("@")[0].slice(0, 2).toUpperCase()}
                    </Avatar>
                  </ThemedTooltip>
                ))}
              </StyledAvatarGroup>
            </VStack>
            {appStatus && (
              <VStack spacing="6px">
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "rgba(0, 40, 69, 0.7)",
                  }}
                >
                  Status
                </Typography>
                <HStack spacing="4px">
                  <ErrorIcon
                    style={{
                      color: "#FF0000",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  <Typography
                    styles={{
                      color: "#002845",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {appStatus}
                  </Typography>
                </HStack>
              </VStack>
            )}
          </HStack>
        </HStack>
      </StyledAppCard>

      {/* SDK Monitoring Dialog */}
      {dialogState === APP_DIALOG_STATES_ENUM.MONITORING && (
        <Suspense fallback={<DialogLoader />}>
          <SDKMonitoringDialog
            open={dialogState === APP_DIALOG_STATES_ENUM.MONITORING}
            onClose={() => {
              setDialogState(APP_DIALOG_STATES_ENUM.CLOSE)
            }}
            selectedAppId={app?.app_id}
            enabled={monitoring}
            setMonitoring={setMonitoring}
          />
        </Suspense>
      )}

      {/* Delete App Dialog */}
      {dialogState === APP_DIALOG_STATES_ENUM.DELETE && (
        <Suspense fallback={<DialogLoader />}>
          <DeleteAppDialog
            open={dialogState === APP_DIALOG_STATES_ENUM.DELETE}
            onClose={() => {
              setDialogState(APP_DIALOG_STATES_ENUM.CLOSE)
            }}
            app={app}
          />
        </Suspense>
      )}
    </>
  )
}
