import produce from "immer"
import {
  SET_APP_ERROR,
  SET_APP_LOADING,
  SET_BUNDLE_ID,
  SET_EVENT_LOGGING_TYPE,
  SET_FCM_KEY,
  SET_IS_ANDROID_X,
  SET_PROGRESS,
  SET_APP_DATA,
} from "./constants"
import { EVENT_LOGGING_OPTIONS_ENUM } from "./integrate/utils"

export const initial_state = {
  bundle_id: "",
  fcm_key: "",
  is_android_x: true,
  event_logging_type: EVENT_LOGGING_OPTIONS_ENUM.NONE,
  progress: 0,
  app_data: {},
  app_loading: false,
  app_error: null,
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_BUNDLE_ID:
      draft.bundle_id = action.payload
      break
    case SET_FCM_KEY:
      draft.fcm_key = action.payload
      break
    case SET_IS_ANDROID_X:
      draft.is_android_x = action.payload
      break
    case SET_EVENT_LOGGING_TYPE:
      draft.event_logging_type = action.payload
      break
    case SET_PROGRESS:
      draft.progress = action.payload
      break
    case SET_APP_DATA:
      draft.app_data = action.payload
      break
    case SET_APP_LOADING:
      draft.app_loading = action.payload
      break
    case SET_APP_ERROR:
      draft.app_error = action.payload
      break
    default:
      break
  }
})
