import { callApi } from "../../api"

/**
 * @typedef {Object} OrganizationDTO
 * @property {string} name The name of the organization
 * @property {string} icon_url The url of the organization's icon
 */

/**
 * Creates a new organization.
 * @param {OrganizationDTO} details The organization's name & icon's URL.
 * @returns {Promise<OrganizationDTO>} The created organization.
 */
export function createNewOrgAPI(details) {
  const config = {
    method: "POST",
    body: JSON.stringify(details),
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi("organisations", config, onSuccessCallback, onFailureCallback)
}

/**
 * Fetches all organizations.
 * @returns {Promise<OrganizationDTO[]>} The list of organizations.
 */
export function getAllOrgsAPI() {
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    "customers/organisations",
    config,
    onSuccessCallback,
    onFailureCallback,
    null,
    false,
  )
}

/**
 * Fetches a single organization.
 * @param {string} org_id The ID of the organization to fetch.
 * @returns {Promise<OrganizationDTO>} The organization.
 */
export function getOrgByIdAPI(org_id) {
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `organisations?orgId=${org_id}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Fetches all apps under an organization by ID.
 * @param {string} org_id The ID of the organization whose apps to fetch.
 * @returns {Promise<[]>} The list of apps in that organization.
 */
export function getAppsFromOrgIdAPI(org_id) {
  const config = {
    method: "GET",
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(
    `orgs/apps?orgId=${org_id}`,
    config,
    onSuccessCallback,
    onFailureCallback,
    null,
    false,
  )
}

/**
 * Updates an organization's details.
 * @param {string} org_id The ID of the organization.
 * @param {OrganizationDTO} details The organization's name & icon's URL.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the organization's details were updated successfully, `false` otherwise.
 */
export function updateOrgDetailsAPI(org_id, details) {
  const config = {
    method: "PUT",
    body: JSON.stringify(details),
  }
  const onSuccessCallback = () => {
    return true
  }
  const onFailureCallback = () => {
    return false
  }
  return callApi(
    `organisations?orgId=${org_id}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Deletes an organisation by ID.
 * @param {string} org_id The ID of the organization.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the organization was deleted successfully, `false` otherwise.
 */
export function deleteOrgAPI(org_id) {
  const config = {
    method: "DELETE",
  }
  const onSuccessCallback = () => {
    return true
  }
  const onFailureCallback = () => {
    return false
  }
  return callApi(
    `organisations?orgId=${org_id}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}
