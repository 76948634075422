import {
  Box,
  Button,
  Grid,
  MuiThemeProvider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import LogoutIcon from "@material-ui/icons/ExitToAppSharp"
import HomeIcon from "@material-ui/icons/HomeOutlined"
import classNames from "classnames"
import { useMemo } from "react"
import { Link, Redirect, useLocation, useParams } from "react-router-dom"
import { APP_PLATFORMS } from "../../constants"
import { useAuth } from "../../contexts/AuthContext"
import { useDeepCompareEffect } from "../../utils/use-deep-compare"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import { ReactComponent as IntExpLaptop } from "../Auth/assets/integrate-stepper-laptop.svg"
import useIntegration from "../Auth/integration-experience/hooks/use-integration"
import HStack from "../ReusableComponents/HStack"
import { getAppDataAPI } from "../common/actions"
import {
  SET_APP_DATA,
  SET_APP_ERROR,
  SET_APP_LOADING,
  SET_BUNDLE_ID,
  SET_FCM_KEY,
} from "./constants"
import { useTracked } from "./store"

const STEPS = [
  "Add Application",
  "Integrate",
  // "Test Connection",
  "You are all set!",
]

const QontoConnector = withStyles({
  root: {
    marginLeft: "6.5px",
  },
  active: {
    "& $line": {
      borderColor: "#8C583B",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#8C583B",
    },
  },
  line: {
    borderColor: "#5A7286",
    borderWidth: 1.4,
  },
})(StepConnector)

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#5A7286",
  },
  active: {
    color: theme.palette.primary.main,
  },
  normal: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    color: "",
  },
  completed: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    color: "#8C583B",
  },
}))

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props
  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.completed} />
      ) : (
        <div className={classes.normal} />
      )}
    </div>
  )
}

export default function IntegrationLayout({ children }) {
  const auth = useAuth()
  const { pathname } = useLocation()
  const { orgId, appId } = useParams()
  const { getFCMKeyAndBundleID } = useIntegration()
  const [state, dispatch] = useTracked()
  const { app_error } = state

  useDeepCompareEffect(() => {
    ;(async () => {
      if (pathname !== `/orgs/${orgId}/apps/create`) {
        try {
          dispatch({ type: SET_APP_LOADING, payload: true })

          const appData = await getAppDataAPI(auth, appId)
          dispatch({ type: SET_APP_DATA, payload: appData })

          const appName = appData?.basic_info?.app_name ?? ""
          const platform =
            appData?.basic_info?.platform ?? APP_PLATFORMS.android

          const res = await getFCMKeyAndBundleID(appId, appName, platform)
          const { bundleId } = res
          dispatch({
            type: SET_BUNDLE_ID,
            payload: bundleId ?? "",
          })
          if (platform === APP_PLATFORMS.android) {
            const { fcmKey } = res
            dispatch({
              type: SET_FCM_KEY,
              payload: fcmKey ?? "",
            })
          }
        } catch (error) {
          dispatch({ type: SET_APP_ERROR, payload: error })
        } finally {
          dispatch({ type: SET_APP_LOADING, payload: false })
        }
      }
    })()
  }, [orgId, appId, dispatch, getFCMKeyAndBundleID, pathname, auth])

  const stepper_index = useMemo(() => {
    if (pathname === `/orgs/${orgId}/apps/create`) {
      return 0
    } else if (pathname === `/orgs/${orgId}/apps/${appId}/integrate`) {
      return 1
      // } else if (pathname === `/orgs/${orgId}/apps/${appId}/test-connection`) {
      //   return 2
    } else if (pathname === `/orgs/${orgId}/apps/${appId}/dashboard`) {
      // return 3
      return 2
    }
  }, [orgId, appId, pathname])

  return (
    <>
      <MuiThemeProvider theme={apxTheme}>
        <Grid
          container
          style={{
            margin: "-8px",
            minHeight: "100vh",
          }}
        >
          <Grid
            item
            xs={4}
            style={{
              backgroundColor: "#002845",
              padding: "35px 60px",
            }}
          >
            <Box
              style={{
                justifyContent: "center",
                alignItems: "start",
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src="/assets/img/logo-white.png"
                alt="Apxor Logo"
                height="30px"
                style={{
                  marginBottom: "60px",
                }}
              />
              <Stepper
                orientation="vertical"
                activeStep={stepper_index}
                style={{ background: "none", padding: 0 }}
                connector={<QontoConnector />}
              >
                {STEPS.map((label, index) => (
                  <Step key={`${label} - ${index}`}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      <Link
                        to={
                          stepper_index !== 0 &&
                          stepper_index !== 3 &&
                          label !== "Add Application" &&
                          label !== "You are all set!"
                            ? `${label.replace(/\s+/g, "-").toLowerCase()}`
                            : "#"
                        }
                        style={Object.assign(
                          {
                            textDecoration: "none",
                          },
                          stepper_index !== 0 &&
                            stepper_index !== 3 &&
                            label !== "Add Application" &&
                            label !== "You are all set!"
                            ? { cursor: "pointer" }
                            : { cursor: "auto" },
                        )}
                      >
                        <Typography
                          style={{
                            lineHeight: "38px",
                            fontSize: "18px",
                            fontWeight: "600",
                            color:
                              stepper_index < index
                                ? "#FFFFFF"
                                : stepper_index === index
                                ? apxTheme.palette.primary.main
                                : "#8C583B",
                          }}
                        >
                          {label}
                        </Typography>
                      </Link>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <IntExpLaptop
                style={{
                  marginTop: "60px",
                }}
              />
              <HStack
                spacing="30px"
                style={{
                  position: "absolute",
                  bottom: "20px",
                }}
              >
                <Link
                  to={`/orgs/${orgId}/apps`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{
                      borderWidth: "1.5px",
                    }}
                    startIcon={
                      <HomeIcon style={{ width: "16px", height: "16px" }} />
                    }
                  >
                    Go Home
                  </Button>
                </Link>
                <Link
                  to="/logout"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                      borderWidth: "1.5px",
                    }}
                    startIcon={
                      <LogoutIcon
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    }
                  >
                    Logout
                  </Button>
                </Link>
              </HStack>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              padding: "60px",
              backgroundColor: "#ffffff",
              minHeight: "100%",
            }}
          >
            {children}
          </Grid>
        </Grid>
      </MuiThemeProvider>
      {app_error === "App not found" && <Redirect to="/unauthorised" />}
    </>
  )
}
