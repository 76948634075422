import { withStyles } from "@material-ui/core/styles"
import { useEffect } from "react"
import { Route, useLocation, useParams } from "react-router-dom"
import ApplicationFrame from "../ApplicationFrame"
import { globalStyles } from "../ApplicationFrame/styling/CustomCSS"
import IntegrationFinish from "../IntegrationExperience/finish-integration/components/IntegrationFinish"
import { SET_ORG_DATA } from "../Organizations/constants"
import useOrganization from "../Organizations/hooks/use-organization"
import { useDispatch } from "../Organizations/store"

function Dashboard() {
  const { pathname, state } = useLocation()
  const { orgId } = useParams()

  const { getOrgByID } = useOrganization()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const res = await getOrgByID(orgId)
      dispatch({
        type: SET_ORG_DATA,
        payload: res,
      })
    })()
  }, [dispatch, getOrgByID, orgId])

  if (pathname.endsWith("/dashboard") && state?.fromTestConnection) {
    return <IntegrationFinish />
  }

  return (
    <div>
      <Route
        path="/orgs/:orgId/apps/:appId"
        render={(props) => (
          <ApplicationFrame
            isNavbarEnabled={true}
            {...props}
          />
        )}
      ></Route>
    </div>
  )
}

export default withStyles(globalStyles, { withTheme: true })(Dashboard)
