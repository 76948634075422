import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined"
import ClockIcon from "@material-ui/icons/ScheduleOutlined"
import { Suspense, lazy, useCallback, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import androidLogo from "../../../../Application/Dashboard/common/components/assets/android.png"
import iosLogo from "../../../../Application/Dashboard/common/components/assets/ios.png"
import webLogo from "../../../../Application/Dashboard/common/components/assets/web.png"
import { APP_PLATFORMS } from "../../../../constants"
import { useAuth } from "../../../../contexts/AuthContext"
import { useDeepCompareMemo } from "../../../../utils/use-deep-compare"
import useIntegration from "../../../Auth/integration-experience/hooks/use-integration"
import Loading from "../../../ReusableComponents/Loading"
import IntegrationLayout from "../../IntegrationLayout"
import {
  SET_APP_DATA,
  SET_EVENT_LOGGING_TYPE,
  SET_IS_ANDROID_X,
  SET_PROGRESS,
} from "../../constants"
import { useDispatch, useTrackedState } from "../../store"
import CopyContent from "../components/CopyContent"
import { EVENT_LOGGING_OPTIONS_ENUM } from "../utils"

const IntegrateByMySelfSection = lazy(() =>
  import("../by-myself/IntegrateByMySelfSection"),
)
const InviteDevsSection = lazy(() => import("../invite-devs/InviteDevsSection"))

const TAB_HEADERS = ["Integrate by MySelf", "Invite Developers"]

export default function IntegratePage() {
  const auth = useAuth()
  const {
    app_loading,
    bundle_id,
    progress,
    is_android_x,
    event_logging_type,
    app_data,
  } = useTrackedState()
  const dispatch = useDispatch()
  const { auth_loading } = auth
  const { push } = useHistory()
  const { orgId, appId } = useParams()
  const { setIntegrationStatusToTrue } = useIntegration()

  const [selectedTab, setSelectedTab] = useState(0)
  const [openInviteDevelopersDialog, setOpenInviteDevelopersDialog] =
    useState(false)

  // const handleIntegrationComplete = useCallback(async () => {
  //   push(`/orgs/${orgId}/apps/${appId}/test-connection`)
  // }, [orgId, appId, push])
  const handleIntegrationComplete = useCallback(async () => {
    const done = await setIntegrationStatusToTrue(appId)
    dispatch({
      type: SET_APP_DATA,
      payload: {
        ...app_data,
        integrated: true,
      },
    })
    if (done) {
      push({
        pathname: `/orgs/${orgId}/apps/${appId}/dashboard`,
        state: {
          fromTestConnection: true,
        },
      })
    }
  }, [setIntegrationStatusToTrue, appId, dispatch, app_data, push, orgId])

  const [webInterimAccepted, setWebInterimAccepted] = useState(false)

  const isCompleteButtonDisabled = useDeepCompareMemo(() => {
    if (selectedTab === 0) {
      switch (app_data?.basic_info?.platform) {
        case APP_PLATFORMS.android:
          if (
            (is_android_x && progress === 8) ||
            (!is_android_x && progress === 6)
          ) {
            return event_logging_type === EVENT_LOGGING_OPTIONS_ENUM.NONE
          }
          break
        case APP_PLATFORMS.ios:
          return progress !== 3
        case APP_PLATFORMS.web:
          return webInterimAccepted === false
        default:
          break
      }
    } else if (selectedTab === 1) {
      return app_data?.integrated === false && bundle_id === ""
    }
    return true
  }, [
    app_data,
    bundle_id,
    event_logging_type,
    is_android_x,
    progress,
    selectedTab,
    webInterimAccepted,
  ])

  return (
    <IntegrationLayout>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "24px",
            }}
          >
            Integrate
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                app_data?.basic_info?.platform === "android"
                  ? androidLogo
                  : app_data?.basic_info?.platform === "ios"
                  ? iosLogo
                  : webLogo
              }
              height="18px"
              style={{
                marginRight: "6px",
              }}
              alt={`${app_data?.basic_info?.platform} icon`}
            />
            <Typography
              style={{
                fontWeight: "500",
                fontSize: "13px",
                marginRight: "7px",
              }}
            >
              {app_data?.basic_info?.app_name}
            </Typography>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "13px",
                opacity: "0.5",
                marginRight: "4px",
              }}
            >
              App ID
            </Typography>
            <CopyContent
              copyText={appId}
              styles={{
                position: "unset",
                fontSize: "20px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            borderBottom:
              selectedTab === 0 ? "1px solid rgba(0, 40, 69, 0.15)" : "none",
          }}
        >
          <Tabs
            value={selectedTab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(_, value) => {
              setSelectedTab(value)
              dispatch({
                type: SET_PROGRESS,
                payload: 0,
              })
              dispatch({
                type: SET_IS_ANDROID_X,
                payload: true,
              })
              dispatch({
                type: SET_EVENT_LOGGING_TYPE,
                payload: EVENT_LOGGING_OPTIONS_ENUM.NONE,
              })
            }}
          >
            {TAB_HEADERS.map((label, index) => (
              <Tab
                disableRipple
                key={`${label}-${index}`}
                label={label}
                style={{
                  textTransform: "none",
                  paddingBottom: "10px",
                  paddingLeft: "0px",
                  marginLeft: "-5px",
                  marginRight: "15px",
                }}
              />
            ))}
          </Tabs>
          {selectedTab === 1 && app_data?.basic_info?.customers?.length > 0 && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<PersonAddOutlinedIcon />}
              onClick={() => setOpenInviteDevelopersDialog(true)}
              style={{
                fontWeight: "600",
                fontSize: "14px",
              }}
              disabled={auth_loading}
            >
              Invite
            </Button>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Suspense
            fallback={
              <Box
                style={{
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loading size={25} />
              </Box>
            }
          >
            {selectedTab === 0 && <IntegrateByMySelfSection />}
            {selectedTab === 1 && (
              <InviteDevsSection
                openInviteDevelopersDialog={openInviteDevelopersDialog}
                setOpenInviteDevelopersDialog={setOpenInviteDevelopersDialog}
              />
            )}
          </Suspense>
        </Grid>
      </Grid>
      <Box
        style={{
          marginTop:
            app_data?.basic_info?.platform === APP_PLATFORMS.web
              ? "20px"
              : "65px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        {selectedTab === 0 &&
          app_data?.basic_info?.platform === APP_PLATFORMS.web && (
            <Box
              style={{
                marginBottom: "20px",
              }}
            >
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    color="primary"
                    value={webInterimAccepted}
                    onChange={(_e, checked) => {
                      setWebInterimAccepted(checked)
                    }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                    }}
                  >
                    I have integrated the <b>Apxor Web SDK</b> to my codebase. I
                    understand that I will need it to proceed further and test
                    my connection.
                  </Typography>
                }
                labelPlacement="end"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              />
            </Box>
          )}
        <Button
          disabled={isCompleteButtonDisabled}
          color="primary"
          size="large"
          variant="contained"
          style={{
            textDecoration: "none",
            color: "white",
          }}
          onClick={handleIntegrationComplete}
        >
          {app_loading ? (
            <Loading
              size={28}
              color="#FFFFFF"
            />
          ) : (
            "Integration Complete"
          )}
        </Button>
        {selectedTab === 1 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <ClockIcon
              color="primary"
              style={{
                fontSize: "16px",
                marginRight: "4px",
              }}
            />
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: "400",
                opacity: 0.6,
              }}
            >
              Waiting for integration to complete...
            </Typography>
          </Box>
        )}
      </Box>
    </IntegrationLayout>
  )
}
