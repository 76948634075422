import { Box, Button, Fab, Menu, Typography } from "@material-ui/core"
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles"
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import { setUserProperties } from "apxor"
import classNames from "classnames"
import moment from "moment"
import momentTz from "moment-timezone"
import { Suspense, lazy, useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import { DEMO_ECOMMERCE_APP_ID, isExperienceDashboard } from "../../config"
import { ON_APP_STATE_CHANGE } from "../../constants"
import { DASHBOARD_ENDPOINTS } from "../../constants/Endpoints"
import { useAuth } from "../../contexts/AuthContext"
import useApxorClient from "../../utils/use-apxor-client"
import {
  useDeepCompareCallbackNoCheck,
  useDeepCompareMemo,
  useDeepCompareMemoNoCheck,
} from "../../utils/use-deep-compare"
import { ApxEventHandler } from "../Dashboard/common/ApxEventHandler"
import {
  FLAGS_ENUM,
  PERMISSIONS_ENUM,
  ROLES_ENUM,
} from "../Dashboard/components/settings/constants"
import { FEATURES_LIST_ENUM } from "../Dashboard/components/settings/general/constants"
import { SET_APP_DATA } from "../IntegrationExperience/constants"
import { useDispatch, useTrackedState } from "../IntegrationExperience/store"
import useUser from "../Profile/hooks/use-user"
import ColumnDiv from "../ReusableComponents/ColumnDiv"
import Loading from "../ReusableComponents/Loading"
import Snackbar from "../ReusableComponents/Snackbar"
import ThemedTooltip from "../ReusableComponents/ThemedTooltip"
import VStack from "../ReusableComponents/VStack"
import DialogLoader from "../ReusableComponents/skeletons/DialogLoader"
import { getAppDataAPI } from "../common/actions"
import { AppHeader } from "./components"
import Navigation from "./components/Navigation"
import NavigationNew from "./components/NavigationNew"
import { useSelector } from "./state"
import { apxTheme, globalStyles } from "./styling/CustomCSS"

/* PAGE IMPORTS */

// Nudges
const Dashboard = lazy(() => import("../Dashboard/components/dashboard"))
const Walkthroughs = lazy(() => import("../Dashboard/components/walkthroughs"))
const Surveys = lazy(() => import("../Dashboard/components/surveys"))
const Push = lazy(() => import("../Dashboard/components/push"))

// Analytics
const Uninstalls = lazy(() => import("../Dashboard/components/uninstalls"))
const Preprocess = lazy(() => import("../Dashboard/components/preprocess"))
const Funnels = lazy(() => import("../Dashboard/components/funnels"))
const Paths = lazy(() => import("../Dashboard/components/paths"))
const Retention = lazy(() => import("../Dashboard/components/retention"))

// Reporting
const CustomReports = lazy(() =>
  import("../Dashboard/components/customreports"),
)

// Explorer
const UserExplorer = lazy(() => import("../Dashboard/components/userexplorer"))
const EventAnalysis = lazy(() =>
  import("../Dashboard/components/eventanalysis"),
)
const User = lazy(() =>
  import("../Dashboard/components/userexplorer/userAnalysis"),
)

// Target Audience
const Cohorts = lazy(() =>
  import("../Dashboard/components/cohorts/pages/CohortsPage"),
)
const DynamicCohorts = lazy(() =>
  import("../Dashboard/components/dynamic-cohorts/pages/DynamicCohortsPage"),
)
const Segments = lazy(() => import("../Dashboard/components/segments"))

// Settings
const IAM = lazy(() => import("../Dashboard/components/settings/IAM/new"))
const TestDevices = lazy(() =>
  import("../Dashboard/components/settings/test-devices"),
)
const Downloads = lazy(() =>
  import("../Dashboard/components/settings/downloads/pages/DownloadsPage"),
)
const EventsManagement = lazy(() =>
  import("../Dashboard/components/settings/EventsManagement"),
)
const ManagePlan = lazy(() =>
  import("../Dashboard/components/settings/manage-plan/pages"),
)
const SDK = lazy(() =>
  import("../Dashboard/components/settings/sdk/pages/SDKPage"),
)
const GeneralSettings = lazy(() =>
  import(
    "../Dashboard/components/settings/general/pages/GeneralSettingsTogglePage"
  ),
)

// Theming
const GlobalThemes = lazy(() =>
  import("../Dashboard/components/themes/common/pages"),
)
const CustomFonts = lazy(() =>
  import("../Dashboard/components/themes/common/layouts/CustomFontsHolder"),
)

const UserRequestsPAge = lazy(() =>
  import("../App/pages/workflowRequest/UserRequestsPAge"),
)

// Dialog Lazy Imports
const ContactUsDialog = lazy(() => import("./components/ContactUsDialog"))
const AppUnderReviewDialog = lazy(() =>
  import("../IntegrationExperience/components/AppUnderReviewDialog"),
)

const Error404 = lazy(() => import("../Error/404"))

function Wrapper({ children }) {
  return (
    <Suspense
      fallback={
        <VStack
          justify="center"
          align="center"
          style={{ height: "80vh" }}
        >
          <Loading
            size={20}
            color={"primary"}
          />
        </VStack>
      }
    >
      {children}
    </Suspense>
  )
}

function ApplicationFrame(props) {
  const {
    classes,
    children,
    isNavbarEnabled = true,
    isAppsPage = false,
  } = props

  const auth = useAuth()
  const { orgId, appId } = useParams()
  const { pathname } = useLocation()
  const { app_data = {} } = useTrackedState()
  const { features = [] } = app_data
  const [open, setOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const { path } = useRouteMatch()

  const {
    user: { email },
  } = auth

  const state = props?.location?.state
  const [app, setApp] = useState(app_data)

  const { currentRefinedUser } = useUser()

  const [isFailed, setFailed] = useState(false)
  const [pendingAppAPI, setPendingAppAPI] = useState(false)
  const [showUnApprovedAppDialog, setShowUnApprovedAppDialog] = useState(false)
  const dispatch = useDispatch()

  const { push } = useHistory()

  useEffect(() => {
    const fetchApp = () => {
      setFailed(false)
      setPendingAppAPI(true)
      getAppDataAPI(auth, appId)
        .then((response) => {
          setApp(response)
          dispatch({
            type: SET_APP_DATA,
            payload: response,
          })
          auth.setAppId(response?.app_id)
          setPendingAppAPI(false)

          if (response?.integrated === false) {
            push(`/orgs/${orgId}/apps/${appId}/integrate`)
          }
          if (response?.basic_info?.approved === false) {
            setShowUnApprovedAppDialog(true)
          }
        })
        .catch(() => {
          setFailed(true)
          setPendingAppAPI(false)
        })
    }

    if (!isAppsPage && (!state || !state.app)) {
      fetchApp()
    } else if (state?.app) {
      auth.setAppId(state.app.app_id)
    }
    const onAppStateChangeSubscriber = ApxEventHandler.subscribe(
      ON_APP_STATE_CHANGE,
      fetchApp,
    )

    return () => {
      onAppStateChangeSubscriber.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (app) {
      setUserProperties({
        timeZone: app?.settings?.timezone || "UTC",
        userId: email,
      })

      momentTz.tz.setDefault(app?.settings?.timezone || "UTC")
    }
  }, [app, email])

  const handleDrawerOpen = () => {
    setOpen(true)
    setCollapsed(false)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setCollapsed(true)
  }

  const demoPeriodDaysLeft =
    isExperienceDashboard() && auth?.user?.demo_period_end_date
      ? Math.ceil(
          moment(auth?.user?.demo_period_end_date).diff(
            moment(new Date().toUTCString()),
            "days",
            true,
          ),
        )
      : 0

  const isAuthenticated = useDeepCompareMemo(
    () => auth?.user?.email && auth?.user?.email !== "",
    [auth],
  )

  const path_arr = pathname.split("/")
  const nudge_id = ["edit", "analysis"].some((x) => path_arr.at(-1) === x)
    ? path_arr.at(-2)
    : ""

  const [anchorEl, setAnchorEl] = useState(null)
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [contactUsMessage, setContactUsMessage] = useState("")
  const { key } = useSelector()
  const docsLinks = useMemo(() => {
    return Object.values(key)
  }, [key])

  function HelpMenu() {
    const { logEvent } = useApxorClient()

    return (
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        MenuListProps={{
          style: {
            padding: "0px",
            backgroundColor: apxTheme.palette.text.primary,
          },
        }}
        style={{
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
          borderRadius: "4px",
          display: "relative",
        }}
      >
        <Box>
          <ColumnDiv
            styles={{
              alignItems: "flex-start",
              gap: "10px",
              padding: "12px",
              backgroundColor: "#002845",
              maxWidth: "240px",
            }}
          >
            {docsLinks.length > 0 &&
              docsLinks.map((doc) => (
                <Button
                  startIcon={
                    <DescriptionIcon
                      color="secondary"
                      style={{
                        marginLeft: "-1px",
                        marginRight: "-1px",
                      }}
                    />
                  }
                  variant="text"
                  size="small"
                  component="a"
                  target="_blank"
                  href={doc.link}
                  rel="noopener noreferrer"
                  onClick={handleClose}
                >
                  <Typography
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {doc.name}
                  </Typography>
                </Button>
              ))}
            {docsLinks.length === 0 && (
              <Button
                startIcon={
                  <DescriptionIcon
                    color="secondary"
                    style={{
                      marginLeft: "-1px",
                      marginRight: "-1px",
                    }}
                  />
                }
                variant="text"
                size="small"
                component="a"
                target="_blank"
                href="https://guides.apxor.com/product-guides"
                rel="noopener noreferrer"
                onClick={handleClose}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Documentation
                </Typography>
              </Button>
            )}
            <Button
              startIcon={<MailOutlineIcon color="secondary" />}
              variant="text"
              size="small"
              onClick={() => {
                setContactUsDialogOpen(true)
                logEvent("ContactUsClicked", {
                  nudge_id,
                  app_id: appId,
                })
                handleClose()
              }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Contact Us
              </Typography>
            </Button>
            {["dev.internal.apxor.com", "qa.apxor.com"].includes(
              window.location.hostname,
            ) && (
              <Button
                startIcon={<OpenInNewIcon color="secondary" />}
                variant="text"
                size="small"
                onClick={() => {
                  handleClose()
                  const localhostURL = `https://localhost${window.location.pathname}${window.location.search}`
                  window.open(localhostURL, "_blank")
                }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Open in localhost
                </Typography>
              </Button>
            )}
          </ColumnDiv>
        </Box>
      </Menu>
    )
  }

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleMouseEnter = () => {
    setDrawerOpen(true)
  }

  const handleMouseLeave = () => {
    setDrawerOpen(false)
  }

  const isRouteEnabled = useDeepCompareCallbackNoCheck(
    (featureKey) => {
      let bools = [
        features.includes(featureKey), // enabled for App
        currentRefinedUser?.flags?.includes(featureKey), // enabled for User
      ]

      if (
        featureKey === FEATURES_LIST_ENUM.SETTINGS.children.DOWNLOADS.value &&
        currentRefinedUser?.role?.value === ROLES_ENUM.SUPPORT.value
      ) {
        bools.push(
          currentRefinedUser?.permissions?.includes(
            PERMISSIONS_ENUM.REPORT_DOWNLOAD.value,
          ) ?? false,
        )
      }

      return bools.every((bool) => bool === true)
    },
    [
      features,
      currentRefinedUser?.flags,
      currentRefinedUser?.role,
      currentRefinedUser?.permissions,
    ],
  )

  const routeMapper = useDeepCompareCallbackNoCheck(
    (app, path) => [
      /* NUDGES */

      // Dashboard
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.NUDGES.children.DASHBOARD.value,
        ),
        component: (
          <Wrapper>
            <Dashboard app={app} />
          </Wrapper>
        ),
      },

      // Walkthroughs
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.WALKTHROUGHS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.NUDGES.children.CAMPAIGNS.value,
        ),

        component: (
          <Wrapper>
            <Walkthroughs />
          </Wrapper>
        ),
      },

      // Surveys
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SURVEYS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.NUDGES.children.SURVEYS.value,
        ),
        component: (
          <Wrapper>
            <Surveys />
          </Wrapper>
        ),
      },

      // Push
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.PUSH}`,
        enabled: isRouteEnabled(FEATURES_LIST_ENUM.NUDGES.children.PUSH.value),
        component: (
          <Wrapper>
            <Push app={app} />
          </Wrapper>
        ),
      },

      /* ANALYTICS */

      // Retention
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.RETENTION}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.ANALYTICS.children.TEMPLATES_RETENTION.value,
        ),
        component: (
          <Wrapper>
            <Retention app={app} />
          </Wrapper>
        ),
      },

      // Uninstalls
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.UNINSTALLS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.ANALYTICS.children.UNINSTALLS.value,
        ),
        component: (
          <Wrapper>
            <Uninstalls app={app} />
          </Wrapper>
        ),
      },
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.FUNNELS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.ANALYTICS.children.FUNNELS.value,
        ),
        component: <Funnels app={app} />,
      },
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.PATH}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.ANALYTICS.children.PATHS.value,
        ),
        component: <Paths app={app} />,
      },
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.PREPROCESS}`,
        enabled: isRouteEnabled(FLAGS_ENUM.ANALYTICS.value),
        component: <Preprocess app={app} />,
      },

      /* REPORTING */

      // Custom Reports
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.CUSTOM_REPORTS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.REPORTING.children.CUSTOM_REPORTS.value,
        ),
        component: (
          <Wrapper>
            <CustomReports app={app} />
          </Wrapper>
        ),
      },

      /* EXPLORER */

      // Event Explorer
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.EVENT_ANALYSIS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.EXPLORER.children.EVENT_EXPLORER.value,
        ),
        component: (
          <Wrapper>
            <EventAnalysis app={app} />
          </Wrapper>
        ),
      },

      // User Explorer
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.USER_EXPLORER}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.EXPLORER.children.USER_EXPLORER.value,
        ),
        component: (
          <Wrapper>
            <UserExplorer app={app} />
          </Wrapper>
        ),
      },

      // Users from Test Devices
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.USERS}`,
        enabled:
          isRouteEnabled(
            FEATURES_LIST_ENUM.EXPLORER.children.USER_EXPLORER.value,
          ) &&
          isRouteEnabled(
            FEATURES_LIST_ENUM.SETTINGS.children.TEST_DEVICES.value,
          ),
        component: (
          <Wrapper>
            <User app={app} />
          </Wrapper>
        ),
      },

      /* TARGET AUDIENCE */

      // Cohorts
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.COHORTS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.TARGET_AUDIENCE.children.COHORTS.value,
        ),
        component: (
          <ErrorBoundary
            fallback={
              <VStack
                align="center"
                justify="center"
                style={{
                  height: "70vh",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#f44336",
                  }}
                >
                  Something went wrong. Please try again later.
                </Typography>
              </VStack>
            }
          >
            <Wrapper>
              <Cohorts />
            </Wrapper>
          </ErrorBoundary>
        ),
      },

      // Dynamic Cohorts
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.DYNAMIC_COHORTS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.TARGET_AUDIENCE.children.DYNAMIC_COHORTS.value,
        ),
        component: (
          <Wrapper>
            <DynamicCohorts />
          </Wrapper>
        ),
      },

      // Segments
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SEGMENTS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.TARGET_AUDIENCE.children.SEGMENTS.value,
        ),
        component: (
          <Wrapper>
            <Segments app={app} />
          </Wrapper>
        ),
      },

      /* THEMES */

      // Global Themes
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.GLOBAL_THEMES}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.THEMES.children.GLOBAL_THEMES.value,
        ),
        component: (
          <Wrapper>
            <GlobalThemes />
          </Wrapper>
        ),
      },

      // Custom Fonts
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.GLOBAL_THEMES}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.THEMES.children.CUSTOM_FONTS.value,
        ),
        component: (
          <Wrapper>
            <CustomFonts />
          </Wrapper>
        ),
      },

      /* SETTINGS */

      // IAM
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_IAM}`,
        enabled: true,
        component: (
          <Wrapper>
            <IAM app={app} />
          </Wrapper>
        ),
      },

      // Test Devices
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_TEST_DEVICES}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.SETTINGS.children.TEST_DEVICES.value,
        ),
        component: (
          <Wrapper>
            <TestDevices app={app} />
          </Wrapper>
        ),
      },

      // Downloads
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_DATA}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.SETTINGS.children.DOWNLOADS.value,
        ),
        component: (
          <Wrapper>
            <Downloads />
          </Wrapper>
        ),
      },

      // SDK
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_SDK}`,
        enabled: isRouteEnabled(FEATURES_LIST_ENUM.SETTINGS.children.SDK.value),
        component: (
          <Wrapper>
            <SDK app={app} />
          </Wrapper>
        ),
      },

      // Events Management
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_EM}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.SETTINGS.children.EVENTS_MANAGEMENT.value,
        ),
        component: (
          <Wrapper>
            <EventsManagement app={app} />
          </Wrapper>
        ),
      },

      // General Settings
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_GENERAL}`,
        enabled: currentRefinedUser.isAdmin || currentRefinedUser.isOrgOwner,
        component: (
          <Wrapper>
            <GeneralSettings />
          </Wrapper>
        ),
      },

      // Manage Plan
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.SETTINGS_MANAGE_PLAN}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.SETTINGS.children.MANAGEPLAN.value,
        ),
        component: (
          <Wrapper>
            <ManagePlan />
          </Wrapper>
        ),
      },

      // Approval Requests
      {
        path: `${path}/${DASHBOARD_ENDPOINTS.REQUESTS}`,
        enabled: isRouteEnabled(
          FEATURES_LIST_ENUM.NUDGES.children.WORKFLOW_APPROVAL.value,
        ),
        component: (
          <Wrapper>
            <UserRequestsPAge />
          </Wrapper>
        ),
      },
    ],
    [currentRefinedUser.isAdmin, currentRefinedUser.isOrgOwner, isRouteEnabled],
  )

  const uamUser = useDeepCompareMemoNoCheck(
    () =>
      currentRefinedUser?.flags?.filter(
        (flag) => flag !== FEATURES_LIST_ENUM.NUDGES.children.AB.value,
      ),
    [currentRefinedUser?.flags],
  )

  return (
    <MuiThemeProvider theme={apxTheme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          {isAuthenticated && (
            <>
              {isExperienceDashboard() && (
                <AppHeader
                  {...props}
                  appState={app}
                  open={open}
                  pendingAppAPI={pendingAppAPI}
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                  setContactUsDialogOpen={setContactUsDialogOpen}
                />
              )}
              {isNavbarEnabled && app && (
                <>
                  {isExperienceDashboard() ? (
                    <Navigation
                      {...props}
                      appState={app}
                      open={open}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      user={auth?.user}
                      loggedIn={isAuthenticated}
                    />
                  ) : (
                    <NavigationNew
                      {...props}
                      appState={app}
                      open={drawerOpen}
                      user={auth?.user}
                      handleMouseEnter={handleMouseEnter}
                      handleMouseLeave={handleMouseLeave}
                    />
                  )}
                </>
              )}
              {!pendingAppAPI && app && (isNavbarEnabled || open) && (
                <main
                  className={classNames(
                    classes.content,
                    !collapsed && classes.pinnedContent,
                  )}
                >
                  <Switch>
                    {/* Apxor Experience */}
                    {isExperienceDashboard() && demoPeriodDaysLeft === 0 && (
                      <Redirect
                        to={`/orgs/${orgId}/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                      />
                    )}

                    {/* UAM Redirect */}
                    {uamUser?.length === 1 &&
                      uamUser[0] ===
                        FEATURES_LIST_ENUM.SETTINGS.children.IAM.value && (
                        <Redirect
                          to={`/orgs/${orgId}/apps/${appId}/settings/iam`}
                        />
                      )}

                    {/* All Routes */}
                    {routeMapper(app, path)
                      .filter((eachFeatureObject) => eachFeatureObject.enabled)
                      .map((eachFeatureObject) => {
                        return (
                          <Route
                            path={eachFeatureObject.path}
                            key={eachFeatureObject.path}
                          >
                            {eachFeatureObject.component}
                          </Route>
                        )
                      })}

                    <Route path="*">
                      <Suspense fallback={<></>}>
                        <Error404 />
                      </Suspense>
                    </Route>
                  </Switch>

                  <HelpMenu />

                  <ThemedTooltip title="Help">
                    <Fab
                      aria-label="help"
                      size="small"
                      onClick={handleClick}
                      style={{
                        color: "#fff",
                        position: "fixed",
                        bottom: 15,
                        right: 15,
                        zIndex: 10,
                        backgroundColor: "#002845",
                      }}
                    >
                      <Typography
                        color="secondary"
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        ?
                      </Typography>
                    </Fab>
                  </ThemedTooltip>

                  {contactUsDialogOpen && (
                    <Suspense fallback={<DialogLoader />}>
                      <ContactUsDialog
                        open={contactUsDialogOpen}
                        onClose={() => {
                          setContactUsDialogOpen(false)
                        }}
                        setContactUsMessage={setContactUsMessage}
                      />
                    </Suspense>
                  )}

                  {contactUsMessage !== "" && (
                    <Snackbar
                      onClose={() => {
                        setContactUsMessage("")
                      }}
                    >
                      <strong>{contactUsMessage}</strong>
                    </Snackbar>
                  )}

                  {!isAppsPage && !isFailed && pendingAppAPI && (
                    <VStack
                      justify="center"
                      align="center"
                      style={{ height: "80vh" }}
                    >
                      <Loading
                        size={20}
                        color={"primary"}
                      />
                    </VStack>
                  )}
                </main>
              )}

              {!isAppsPage && isFailed && !pendingAppAPI && (
                <Redirect to="/unauthorised" />
              )}
            </>
          )}
          {children}
        </div>
      </div>

      {!pendingAppAPI && showUnApprovedAppDialog && (
        <Suspense fallback={<DialogLoader />}>
          <AppUnderReviewDialog
            open={showUnApprovedAppDialog}
            accountOrApp="APP"
          />
        </Suspense>
      )}
    </MuiThemeProvider>
  )
}

export default withStyles(globalStyles, { withTheme: true })(ApplicationFrame)
