import HeadsetMicIcon from "@material-ui/icons/HeadsetMic"
import LockIcon from "@material-ui/icons/Lock"
import PersonIcon from "@material-ui/icons/Person"
import SettingsIcon from "@material-ui/icons/Settings"
import ColumnDiv from "../../../ReusableComponents/ColumnDiv"

export const SET_SETTINGS_LOADING = "SET_SETTINGS_LOADING"
export const SET_SETTINGS_ERROR = "SET_SETTINGS_ERROR"
export const SET_BUNDLE_IDS = "SET_BUNDLE_IDS"
export const SET_TEST_DEVICES = "SET_TEST_DEVICES"

/**
 * @typedef {Object} RoleType
 * @property {string} name
 * @property {string} value
 * @property {string} color
 * @property {React.Component} icon
 * @property {string} description
 */

/**
 * @typedef {Object} FlagType
 * @property {string} name
 * @property {string} value
 * @property {string} description
 */

/**
 * @typedef {Object} PermissionType
 * @property {string} name
 * @property {string} value
 */

/**
 * @enum {RoleType}
 */
export const ROLES_ENUM = Object.freeze({
  ORG_OWNER: {
    name: "Admin",
    value: "organisation_owner",
    color: "#AF5454",
    icon: (
      <ColumnDiv
        styles={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#DFEEFA",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
        }}
      >
        <SettingsIcon
          style={{ color: "#176FAC", width: "17px", height: "17px" }}
        />
      </ColumnDiv>
    ),
    description:
      "They are the head of the application. Have the ability to perform any action. They usually add the next layer of Product Managers",
  },
  PM: {
    name: "Publisher",
    value: "product_manager",
    color: "#156AB8",
    icon: (
      <ColumnDiv
        styles={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FDF0E9",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
        }}
      >
        <PersonIcon
          style={{ color: "#B95D2B", width: "17px", height: "17px" }}
        />
      </ColumnDiv>
    ),
    description:
      "They can add the next level support users with proper access definitions and do everything except on/off the data  monitoring",
  },
  SUPPORT: {
    name: "Viewer",
    value: "support",
    color: "#D09D07",
    icon: (
      <ColumnDiv
        styles={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FCF1D3",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
        }}
      >
        <HeadsetMicIcon
          style={{ color: "#D9A20C", width: "17px", height: "17px" }}
        />
      </ColumnDiv>
    ),
    description:
      "Can view anything along with create or edit, analyze and download reports. But cannot publish, delete or schedule",
  },
  UAM: {
    name: "User Access Manager",
    value: "user_access_manager",
    color: "purple",
    icon: (
      <ColumnDiv
        styles={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F7E6FF",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
        }}
      >
        <LockIcon style={{ color: "purple", width: "17px", height: "17px" }} />
      </ColumnDiv>
    ),
    description:
      "Can modify user permissions and add and delete users, ensuring precise control over user access and privilages",
  },
})

/**
 * @enum {RoleType}
 */
export const ADDITIONAL_ROLES_ENUM = Object.freeze({
  APXOR_OWNER: {
    name: "Super Admin",
    value: "super_admin",
    color: "#469702",
  },
  CSM: {
    name: "CSM Account Manager",
    value: "product_manager",
    color: "#7E7E7E",
  },
})

/**
 * @enum {FlagType}
 */
export const FLAGS_ENUM = Object.freeze({
  NUDGES: {
    name: "Nudges",
    value: "nudges",
    description: "All permissions on campaigns, surveys, push notifications",
  },
  ANALYTICS: {
    name: "Analytics",
    value: "analytics",
    description: "Retention, uninstalls, funnels and paths",
  },
  REPORTING: {
    name: "Reporting",
    value: "reporting",
    description: "Event explorer and user explorer",
  },
  EXPLORER: {
    name: "Explorer",
    value: "explorer",
    description: "Analyze and download event explorer and user explorer",
  },
  TARGET_AUDIENCE: {
    name: "Target Audience",
    value: "target_audience",
    description: "Manage cohorts and segments",
  },
  SETTINGS: {
    name: "Settings",
    value: "settings",
    description: "Manage plan",
  },
  THEMES: {
    name: "Themes",
    value: "themes",
    description: "For adding global themes",
  },
})

/**
 * @enum {PermissionType}
 */
export const PERMISSIONS_ENUM = Object.freeze({
  CAMPAIGN_EDIT: { name: "Edit Campaigns & Surveys", value: "campaign_edit" },
  // REPORT_READ: {name: "Analyze Reports", value: "report_read"},
  REPORT_DOWNLOAD: { name: "Download Reports", value: "report_download" },
})

export const ALL_PERMISSIONS = Object.values(PERMISSIONS_ENUM).map(
  (permission) => permission.value,
)

export const SETTINGS_PAGES_IDS = [
  "iam",
  "test-devices",
  "downloads",
  "events",
  "sdk",
  "general",
  "manage-plan",
]

/**
 * Checks if the given user belongs to Apxor CSM Team.
 * @param {RoleType} role The {@link RoleType} of the user to be checked.
 * @param {string} email_id The email id of the user to be checked.
 * @param {string} orgId The organisation id of the user to be checked.
 * @returns {boolean} `true` if the user belongs to Apxor CSM, else `false`.
 */
export const isCSM = (role, email_id, orgId) => {
  return (
    [ROLES_ENUM.PM.value, ROLES_ENUM.SUPPORT.value].includes(role) &&
    email_id.endsWith("@apxor.com") &&
    orgId !== "00a932d0-7a6b-4d0d-8699-b4cafa6b7106"
  )
}

/**
 * @enum {string}
 * @readonly
 * @description The different states of the dialog.
 */
export const DIALOG_TYPES = Object.freeze({
  CLOSE: "close",
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
  VIEW: "view",
})

/**
 * @enum
 * @readonly
 * @description The different statuses of a downloadable file.
 */
export const DOWNLOAD_STATES = Object.freeze({
  IN_PROGRESS: { text: "In Progress", color: "#FFD600" },
  AVAIBALE: { text: "Available", color: "#469702" },
  FAILED: { text: "Failed", color: "#D50000" },
  EXPIRED: { text: "Expired", color: "#7E7E7E" },
})
