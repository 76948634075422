import { MuiThemeProvider, withStyles } from "@material-ui/core"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuth } from "../../../contexts/AuthContext"
import {
  apxTheme,
  globalStyles,
} from "../../ApplicationFrame/styling/CustomCSS"
import { SET_ORG_DATA } from "../../Organizations/constants"
import useOrganization from "../../Organizations/hooks/use-organization"
import { useDispatch } from "../../Organizations/store"
import AppsSideNav from "../components/AppsSideNav"

function AppsLayout({ children, classes }) {
  const { user } = useAuth()
  const { orgId } = useParams()
  const { getOrgByID } = useOrganization()
  const dispatch = useDispatch()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleMouseEnter = () => {
    setDrawerOpen(true)
  }

  const handleMouseLeave = () => {
    setDrawerOpen(false)
  }

  useEffect(() => {
    ;(async () => {
      const res = await getOrgByID(orgId)
      dispatch({
        type: SET_ORG_DATA,
        payload: res,
      })
    })()
  }, [dispatch, getOrgByID, orgId])

  return (
    <MuiThemeProvider theme={apxTheme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppsSideNav
            user={user}
            open={drawerOpen}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
          <main className={classNames(classes.content)}>{children}</main>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

export default withStyles(globalStyles, { withTheme: true })(AppsLayout)
